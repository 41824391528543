import React, { Fragment } from "react";
import Minter from './Components/Minter';

class Nfts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 3000);
  }

  render() {
    // return
    // this.state.loading ? (
    //   <div className="overlay">
    //     <img alt="" src="images/intro.gif" width="100" />
    //   </div>
    // ) :
    return (
      <Fragment>
        <Minter />
        {/* <Banner />
        <Resistance />
        <StrategicPartners />
        <ComunityTokens /> */}
      </Fragment>
    );
  }
}

export default Nfts;
