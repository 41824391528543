const NavItem = ({ title, url }) => {
  const activeUrl = window.location.pathname;
  return (
    <li className={"nav-item" + (activeUrl === url ? " active" : "")}>
      <a href={url} className="nav-link text-uppercase">
        {title}
      </a>
    </li>
  );
};
export default NavItem;
