import React from 'react';
import './styles.css'

const SellBar = () => {
  return (
      <div class="container">

        <input type="radio" class="radio" name="sell" value="zero" id="zero-sell" />
        <label for="zero-sell" class="label">0%</label>

        <input type="radio" class="radio" name="sell" value="twentyfive" id="twentyfive-sell" />
        <label for="twentyfive-sell" class="label">25%</label>

        <input type="radio" class="radio" name="sell" value="fifty" id="fifty-sell" />
        <label for="fifty-sell" class="label">50%</label>

        <input type="radio" class="radio" name="sell" value="seventyfive" id="seventyfive-sell" />
        <label for="seventyfive-sell" class="label">75%</label>

        <input type="radio" class="radio" name="sell" value="onehundred" id="onehundred-sell" />
        <label for="onehundred-sell" class="label">100%</label>

        <div class="sell-progress">
          <div class="sell-progress-bar"></div>
        </div>
      </div>
  );
};

export default SellBar;