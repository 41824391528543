import React from "react";
import BX_logo from "../assets/logo_phone.png";
import Typed from "react-typed";
import solanaLogoHorizontal from "../assets/img/solana_logo_horizontal.png";
import velasHorizontal from "../assets/img/velas_horizontal.png";
import everscaleHorizontal from "../assets/img/everscale.png";
import nearLogo from "../assets/img/near.png";
class Banner extends React.Component {
  render() {
    return (
      <section className="section mt-67 banner" id="banner">
        <div className="black-layer">
          <div className="header container">
            <div className="row w-100 p-0 m-0">
              <div className="col-lg-6 col-md-12">
                <div className="row">
                  <div className="col-12 bounce-in-left">
                    <h1 className=" text-left main-title ff-monte mt-10">
                      Institutional-grade ESG-driven DeFi Trading
                    </h1>
                    <h3 className="ff-monte text-left build-color ">
                      build on:
                    </h3>
                    {/* <div className="main-title ff-monte d-flex align-items-center jc-center text-white banner-logos">
                      <img
                        className="logo-horizontal-banner"
                        src={"images/banner/1.png"}
                        alt="Logo Solana"
                      ></img>
                      <img
                        className="logo-horizontal-banner"
                        src={"images/banner/2.png"}
                        alt="Logo Velas"
                      ></img>
                      <img
                        className="logo-horizontal-banner"
                        src={"images/banner/3.png"}
                        alt="Logo Everscale"
                      ></img>
                      <img
                        className="logo-horizontal-banner"
                        src={"images/banner/4.png"}
                        alt="Logo Solana"
                      ></img>
                    </div> */}
                    <br />
                  </div>
                </div>
                <div className="row mb-10">
                  <div className="col-12">
                    <h3 className="bounce-in-left main-title-4 mt-1 typped">
                      <p className="main-title-6 m-0 p-0 ff-monte ">
                        Every transaction contributes to projects helping:
                      </p>
                      {/* <p className="main-title-9 ff-monte ">
                        Cleaning the Oceans
                      </p> */}
                      <Typed
                        strings={[
                          " Cleaning the Oceans_",
                          " The Environment_",
                          "Impact-driven Initiatives_",
                          "The Biodiversity_",
                          "Clean water",
                          "Climate change",
                        ]}
                        typeSpeed={40}
                        backSpeed={50}
                        loop
                      ></Typed>
                    </h3>
                  </div>
                </div>
                {/* <div className="row justify-content-center slide-in-bottom-pic">
                  <div className="col-12">
                    <img
                      alt=""
                      src={BX_logo}
                      width={100}
                      style={{ marginTop: "10px" }}
                    />
                    {/* <p className='slide-in-bottom text-center main-title-7'>
                      The Decentralized Financial Renaissance
                    </p> */}
                {/* </div>
                </div> */}{" "}
                {/* <div className="row buttons-row justify-content-center ">
                  <div className="col-6 col-md-3">
                    <div className="slide-in-bottom">
                      <a
                        href="https://docsend.com/view/jpr2i3w2vu8ie9n7"
                        className=" btn btn-sm waves-effect waves-blue"
                        // download = "Slide Deck"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Project Overview
                      </a>
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                    <div className="slide-in-bottom">
                      <a
                        href="https://docsend.com/view/a7uhfeikjt7strsj"
                        className="btn btn-sm waves-effect waves-blue"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Tokenomics
                      </a>
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                    <div className="slide-in-bottom ">
                      <a
                        href="https://docsend.com/view/mzue3zgpghrxip56"
                        // href='https://docsend.com/view/uhd8hxmxjyrh9x2r'
                        className="btn btn-sm waves-effect waves-blue"
                        target="_blank"
                        rel="noreferrer"
                      >
                        One-Pager
                      </a>
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                    <div className="slide-in-bottom  ">
                      <a
                        // href="https://docsend.com/view/3i84gv5n7uf87d7p"
                        href="https://docsend.com/view/jpr2i3w2vu8ie9n7"
                        className="btn btn-sm waves-effect waves-blue"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Whitepaper
                      </a>
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                    <div className="slide-in-bottom">
                      <a
                        href=""
                        className=" btn btn-sm waves-effect waves-blue"
                        // download = "Slide Deck"
                        // target="_blank"
                        rel="noreferrer"
                      >
                        Ecosystem
                      </a>
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                    <div className="slide-in-bottom">
                      <a
                        href=""
                        className=" btn btn-sm waves-effect waves-blue"
                        // download = "Slide Deck"
                        // target="_blank"
                        rel="noreferrer"
                      >
                        Ecosystem
                      </a>
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                    <div className="slide-in-bottom">
                      <a
                        href=""
                        className=" btn btn-sm waves-effect waves-blue"
                        // download = "Slide Deck"
                        // target="_blank"
                        rel="noreferrer"
                      >
                        Utility
                      </a>
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                    <div className="slide-in-bottom">
                      <a
                        href=""
                        className=" btn btn-sm waves-effect waves-blue"
                        // download = "Slide Deck"
                        // target="_blank"
                        rel="noreferrer"
                      >
                        Collectibles
                      </a>
                    </div>
                  </div>
                </div> */}
                <div className="d-flex justify-content-between m-4 social-header">
                  <a
                    href="https://discord.com/invite/BXcommunity"
                    target="blank"
                  >
                    <img
                      className="socialMedia-icon"
                      src="images/SocialMediaIcons/white/discord.png"
                      alt=""
                    />
                  </a>
                  <a href="https://twitter.com/bancambios" target="blank">
                    <img
                      className="socialMedia-icon"
                      src="images/SocialMediaIcons/white/twitter.png"
                      alt=""
                    />
                  </a>
                  <a href="https://t.me/BancambiosX" target="blank">
                    <img
                      className="socialMedia-icon"
                      src="images/SocialMediaIcons/white/telegram.png"
                      alt=""
                    />
                  </a>

                  <a href="https://bancambios.medium.com/" target="blank">
                    <img
                      className="socialMedia-icon"
                      src="images/SocialMediaIcons/white/medium.png"
                      alt=""
                    />
                  </a>
                  {/* <a
                  href="https://bitcointalk.org/index.php?topic=5334267.0"
                  target="blank"
                >
                  <img
                    className="socialMedia-icon"
                    src="images/SocialMediaIcons/bitcoinIcon.png"
                    alt=""
                  />
                </a> */}
                  {/* <a
                  href="https://www.youtube.com/channel/UCqEVW72R1T-C2uAEoiFk3KA"
                  target="blank"
                >
                  <img
                    className="socialMedia-icon"
                    src="images/SocialMediaIcons/youtubeIcon.png"
                    alt=""
                  />
                </a> */}
                  <a
                    href="https://www.linkedin.com/company/bancambios"
                    target="blank"
                  >
                    <img
                      className="socialMedia-icon"
                      src="images/SocialMediaIcons/white/linkedin.png"
                      alt=""
                    />
                  </a>
                  <a href="https://github.com/bancambiosdefi" target="blank">
                    <img
                      className="socialMedia-icon"
                      src="images/SocialMediaIcons/github.png"
                      alt=""
                    />
                  </a>
                  <a href="https://www.facebook.com/bancambios" target="blank">
                    <img
                      className="socialMedia-icon"
                      src="images/SocialMediaIcons/white/facebook.png"
                      alt=""
                      // style={{ width: 110, height: 50 }}
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/bancambiosx/"
                    target="blank"
                  >
                    <img
                      className="socialMedia-icon"
                      src="images/SocialMediaIcons/white/instagram.png"
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 mt-20">
                <div className="w-100 banner-right"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Banner;
