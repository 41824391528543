import React, {useState} from 'react'
import Zoom from 'react-reveal/Reveal'
import FormControl from 'react-bootstrap/FormControl';
import Dropdown from 'react-bootstrap/Dropdown';

  
  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const customToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{cursor:'pointer'}}
    >
      <div className='border-gradient br-10'>
        <div className='br-10 bg-dark-grey d-flex align-items-center justify-content-space-between p-2 pr-4 pl-4 w-100'>
          <div className='p1'>
            <i className="fas fa-search text-white"></i>
          </div>
          <div className='w-100 text-grey'>
            <h6 className='m-0'>Try: SOL to USDC</h6>
          </div>
          <div className='p-1 text-white'>
            <i className="fas fa-chevron-down"></i>
          </div>
        </div>
      </div>
    </div>
  ));

  const customToggleFrom = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{cursor: 'pointer'}}
    >
      <div className='d-flex align-items-center'>
        <div className='d-flex align-items-center'>
          <img alt="" src='/images/swap/SOLANA.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
          <h5 className='ml-3 mb-0 mr-2 text-white'>SOL</h5>
        </div>
        <div>
          <i className="fas fa-chevron-down text-white"></i>
        </div>
      </div>
    </div>
  ));

  const customToggleTo = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{cursor: 'pointer'}}
    >
      <div className='d-flex align-items-center'>
        <div className='d-flex align-items-center'>
          <img alt="" src='/images/swap/USDC.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
          <h5 className='ml-3 mb-0 mr-2 text-white'>USDC</h5>
        </div>
        <div>
          <i className="fas fa-chevron-down text-white"></i>
        </div>
      </div>
    </div>
  ));

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const customMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="my-2 bg-dark-grey-2 border-grey-2 text-grey"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) => 
                !value || child.props.children.props.children[0].props.children[1].props.children.toLowerCase().startsWith(value)
                || child.props.children.props.children[2].props.children[1].props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    },
  );

  const customMenuFrom = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="my-2 bg-dark-grey-2 border-grey-2 text-grey"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled" style={{height: '280px', overflowY: 'auto'}}>
            {React.Children.toArray(children).filter(
              (child) => 
                !value || child.props.children.props.children[1].props.children.toLowerCase().startsWith(value)
                || child.props.children.props.children[1].props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    },
  );

  const customMenuTo = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="my-2 bg-dark-grey-2 border-grey-2 text-grey"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled" style={{height: '280px', overflowY: 'auto'}}>
            {React.Children.toArray(children).filter(
              (child) => 
                !value || child.props.children.props.children[1].props.children.toLowerCase().startsWith(value)
                || child.props.children.props.children[1].props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    },
  );
class Converter extends React.Component {
  componentDidMount() {
  }

  state = {
    walletConnected: false,
    fromValue: '0.0',
    toValue: '0.0',
    manageTolerance: false,
    slippageTolerance: '1',
  } 

  render() {
    return (
      <section className='section converter m-0 p-0' id='converter'>
        <div className='black-layer-2'>
          <div className='container mt-2 mb-3 ptb-12'>
            <div className='row align-items-center justify-content-center mt-4'>
              <Zoom bottom>
                <div className='col-lg-5 col-md-8 col-sm-12'>
                  <div className='card-converter'>
                    <div className='converter-header'>
                      <Dropdown>
                        <Dropdown.Toggle as={customToggle} id="dropdown-custom-components">
                          Custom toggle
                        </Dropdown.Toggle>

                        <Dropdown.Menu as={customMenu} className="bg-grey br-30 p-4 w-100" variant="dark">
                          <Dropdown.Item eventKey="1">
                            <div className='d-flex align-items-center w-100 jc-space-between'>
                              <div className='col-5 p-0 d-flex align-items-center'>
                                <img alt="" src='/images/swap/SOLANA.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                <h5 className='ml-3 mb-0 mr-2 text-white'>SOL</h5>
                              </div>
                              <div className='col-2 p-0 text-center'>
                                <i className="fas fa-chevron-right text-white"></i>
                              </div>
                              <div className='col-5 pr-0 pl-4 d-flex align-items-center '>
                                <img alt="" src='/images/swap/STEP.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                <h5 className='ml-3 mb-0 mr-2 text-white'>STEP</h5>
                              </div>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="1">
                            <div className='d-flex align-items-center w-100 jc-space-between'>
                              <div className='col-5 p-0 d-flex align-items-center'>
                                <img alt="" src='/images/swap/USDC.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                <h5 className='ml-3 mb-0 mr-2 text-white'>USDC</h5>
                              </div>
                              <div className='col-2 p-0 text-center'>
                                <i className="fas fa-chevron-right text-white"></i>
                              </div>
                              <div className='col-5 pr-0 pl-4 d-flex align-items-center '>
                                <img alt="" src='/images/swap/SRM.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                <h5 className='ml-3 mb-0 mr-2 text-white'>SRM</h5>
                              </div>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="1"> 
                            <div className='d-flex align-items-center w-100 jc-space-between'>
                              <div className='col-5 p-0 d-flex align-items-center'>
                                <img alt="" src='/images/swap/SOLANA.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                <h5 className='ml-3 mb-0 mr-2 text-white'>SOL</h5>
                              </div>
                              <div className='col-2 text-center p-0'>
                                <i className="fas fa-chevron-right text-white"></i>
                              </div>
                              <div className='col-5 pr-0 pl-4 d-flex align-items-center '>
                                <img alt="" src='/images/swap/ATLAS.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                <h5 className='ml-3 mb-0 mr-2 text-white'>ATLAS</h5>
                              </div>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="1">
                            <div className='d-flex align-items-center w-100 jc-space-between'>
                              <div className='col-5 p-0 d-flex align-items-center'>
                                <img alt="" src='/images/swap/ATLAS.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                <h5 className='ml-3 mb-0 mr-2 text-white'>MAPS</h5>
                              </div>
                              <div className='col-2 text-center p-0'>
                                <i className="fas fa-chevron-right text-white"></i>
                              </div>
                              <div className='col-5 pr-0 pl-4 d-flex align-items-center '>
                                <img alt="" src='/images/swap/RAY.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                <h5 className='ml-3 mb-0 mr-2 text-white'>RAY</h5>
                              </div>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="1">
                            <div className='d-flex align-items-center w-100 jc-space-between'>
                              <div className='col-5 p-0 d-flex align-items-center'>
                                <img alt="" src='/images/swap/MNGO.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                <h5 className='ml-3 mb-0 mr-2 text-white'>MNGO</h5>
                              </div>
                              <div className='col-2 text-center p-0'>
                                <i className="fas fa-chevron-right text-white"></i>
                              </div>
                              <div className='col-5 pr-0 pl-4 d-flex align-items-center '>
                                <img alt="" src='/images/swap/STEP.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                <h5 className='ml-3 mb-0 mr-2 text-white'>USDC</h5>
                              </div>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="1">
                            <div className='d-flex align-items-center w-100 jc-space-between'>
                              <div className='col-5 d-flex align-items-center'>
                                <img alt="" src='/images/swap/SOLANA.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                <h5 className='ml-3 mb-0 mr-2 text-white'>SOL</h5>
                              </div>
                              <div className='col-2 text-center p-0'>
                                <i className="fas fa-chevron-right text-white"></i>
                              </div>
                              <div className='col-5 d-flex align-items-center jc-flex-end'>
                                <img alt="" src='/images/swap/USDT.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                <h5 className='ml-3 mb-0 mr-2 text-white'>USDT</h5>
                              </div>
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className='converter-body pl-5 pr-5 mt-3'>
                      {this.state.walletConnected === true &&
                        <div className="br-10 bg-grey w-100 p-3 mb-3 d-flex align-items-center jc-space-between">
                          <div className="d-flex align-items-center mr-3">
                            <img alt="" src='/images/swap/SOLANA.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                            <div className='ml-3 mb-0 mr-2 text-white'>
                              <h6>SOL</h6>
                              <span>0.0</span>
                            </div>
                          </div>
                          <div className='w-100'>
                            <img alt="" src='/images/swap/graphGreen.png'></img>
                          </div>
                        </div>
                      }
                      <div className='br-10 mb-3 d-flex bg-grey fd-column p-3'>
                        <div className='form-group w-100 d-flex fd-column mt-2'>
                          <label className='text-left'>From</label>
                          <div className="w-100 d-flex br-10 bg-dark-grey jc-space-between align-items-center p-3">
                            <Dropdown>
                              <Dropdown.Toggle as={customToggleFrom} id="dropdown-custom-components">
                                Custom toggle
                              </Dropdown.Toggle>

                              <Dropdown.Menu as={customMenuFrom} className="bg-grey br-20 p-3" style={{width:'250px'}}>
                                <Dropdown.Item eventKey="1">
                                  <div className='w-100 d-flex align-items-center'>
                                    <img alt="" src='/images/swap/SOLANA.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                    <h5 className='ml-3 mb-0 mr-2 text-white'>SOL</h5>
                                  </div>
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="1">
                                    <div className=' d-flex align-items-center'>
                                      <img alt="" src='/images/swap/USDC.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                      <h5 className='ml-3 mb-0 mr-2 text-white'>USDC</h5>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="1">
                                    <div className='d-flex align-items-center '>
                                      <img alt="" src='/images/swap/SRM.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                      <h5 className='ml-3 mb-0 mr-2 text-white'>SRM</h5>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="1">
                                    <div className='d-flex align-items-center '>
                                      <img alt="" src='/images/swap/ATLAS.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                      <h5 className='ml-3 mb-0 mr-2 text-white'>ATLAS</h5>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="1">
                                    <div className='d-flex align-items-center '>
                                      <img alt="" src='/images/swap/FIDA.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                      <h5 className='ml-3 mb-0 mr-2 text-white'>FIDA</h5>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="1">
                                    <div className='d-flex align-items-center '>
                                      <img alt="" src='/images/swap/MNGO.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                      <h5 className='ml-3 mb-0 mr-2 text-white'>MNGO</h5>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="1">
                                    <div className='d-flex align-items-center '>
                                      <img alt="" src='/images/swap/MER.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                      <h5 className='ml-3 mb-0 mr-2 text-white'>MER</h5>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="1">
                                    <div className='d-flex align-items-center '>
                                      <img alt="" src='/images/swap/RAY.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                      <h5 className='ml-3 mb-0 mr-2 text-white'>RAY</h5>
                                    </div>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            <input type="text" className='input-swap text-white' value={this.state.fromValue} onChange={(e) => this.setState({fromValue: e.currentTarget.value})}></input>
                          </div>
                        </div>
                        <div className='w-100 text-center'>
                          <img alt="" src='/images/team/bgBlack.png' style={{width: '45px', height: '45px', borderRadius: '50%'}}></img>
                        </div>
                        <div className='form-group w-100 d-flex fd-column '>
                          <label className='text-left'>To (Estimated)</label>
                          <div className="w-100 d-flex br-10 bg-dark-grey jc-space-between align-items-center p-3">
                            <Dropdown>
                              <Dropdown.Toggle as={customToggleTo} id="dropdown-custom-components">
                                Custom toggle
                              </Dropdown.Toggle>

                              <Dropdown.Menu as={customMenuTo} className="bg-grey br-20 p-3" style={{width:'250px'}}>
                                <Dropdown.Item eventKey="1">
                                  <div className='w-100 d-flex align-items-center'>
                                    <img alt="" src='/images/swap/SOLANA.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                    <h5 className='ml-3 mb-0 mr-2 text-white'>SOL</h5>
                                  </div>
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="1">
                                    <div className=' d-flex align-items-center'>
                                      <img alt="" src='/images/swap/USDC.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                      <h5 className='ml-3 mb-0 mr-2 text-white'>USDC</h5>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="1">
                                    <div className='d-flex align-items-center '>
                                      <img alt="" src='/images/swap/SRM.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                      <h5 className='ml-3 mb-0 mr-2 text-white'>SRM</h5>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="1">
                                    <div className='d-flex align-items-center '>
                                      <img alt="" src='/images/swap/ATLAS.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                      <h5 className='ml-3 mb-0 mr-2 text-white'>ATLAS</h5>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="1">
                                    <div className='d-flex align-items-center '>
                                      <img alt="" src='/images/swap/FIDA.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                      <h5 className='ml-3 mb-0 mr-2 text-white'>FIDA</h5>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="1">
                                    <div className='d-flex align-items-center '>
                                      <img alt="" src='/images/swap/MNGO.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                      <h5 className='ml-3 mb-0 mr-2 text-white'>MNGO</h5>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="1">
                                    <div className='d-flex align-items-center '>
                                      <img alt="" src='/images/swap/MER.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                      <h5 className='ml-3 mb-0 mr-2 text-white'>MER</h5>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="1">
                                    <div className='d-flex align-items-center '>
                                      <img alt="" src='/images/swap/RAY.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                                      <h5 className='ml-3 mb-0 mr-2 text-white'>RAY</h5>
                                    </div>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            <input type="text" className='input-swap text-white' value={this.state.toValue} onChange={(e) => this.setState({toValue: e.currentTarget.value})}></input>
                          </div>
                        </div>
                        <div className='w-100'>
                          {this.state.walletConnected === false &&
                            <div className="border-gradient br-30 mb-2">
                              <button className='btn br-30 bg-dark-blue w-100 text-white' onClick={() => this.setState({walletConnected: true})}> 
                                Connect Wallet
                              </button>
                            </div>
                          }
                          {this.state.walletConnected === true &&
                            <>
                              <div className='d-flex p-3 align-items-center jc-space-between'>
                                <div>{this.state.fromValue} SOL = 0.04658 ETH </div>
                                <div className='d-flex'>
                                  <button className='btn d-flex align-items-center justify-content-center br-10 bg-light-grey text-grey p-2'
                                    onClick={() => {this.setState({manageTolerance: true})}}>
                                    <i className="fas fa-sync-alt text-white"></i>
                                  </button>
                                  <button className='btn d-flex align-items-center justify-content-center br-10 bg-light-grey text-grey p-2 ml-2'
                                    onClick={() => {this.state.manageTolerance === true ? this.setState({manageTolerance: false}) : this.setState({manageTolerance: true})}}>
                                    <i className="fas fa-cog text-white"></i>
                                  </button>
                                </div>
                              </div>
                              {this.state.manageTolerance === false &&
                                <div className='row bg-light-grey w-100 d-flex py-3 text-left mb-3 br-10 ff-Montserrat'>
                                  <div className='col-8'>Slippage Tolerance </div>
                                  <div className='col-4'>{this.state.slippageTolerance}%</div>
                                  <div className='col-8'>Swapping Trough </div>
                                  <div className='col-4'>BX Pool</div>
                                  <div className='col-8'>Minimum Received </div>
                                  <div className='col-4'>{this.state.toValue} USDC</div>
                                  <div className='col-8'>Price Impact</div>
                                  <div className='col-4'>0.05%</div>
                                </div>
                              }
                              {this.state.manageTolerance === true &&
                                <div className='row bg-light-grey w-100 d-flex py-3 text-left mb-3 br-10 ff-Montserrat'>
                                  <div className='col-12 d-flex align-items-center jc-space-between mb-2'>
                                    <span>Exchange Settings</span>
                                    <i className="fas fa-times text-white" onClick={() => {this.setState({manageTolerance: false})}}></i>
                                  </div>
                                  <div className='col-12'>Slippage Tolerance </div>
                                  <div className='col-12 d-flex align-items-center jc-space-around'>
                                    <button className='btn bg-light-grey text-white' onClick={() => this.setState({slippageTolerance: '0.5%'})}>0.5%</button>
                                    <button className='btn bg-light-grey text-white' onClick={() => this.setState({slippageTolerance: '1%'})}>1%</button>
                                    <button className='btn bg-light-grey text-white' onClick={() => this.setState({slippageTolerance: '1.5%'})}>1.5%</button>
                                    <div>
                                      <input type='text' className='text-right bg-dark-grey br-10 text-white' 
                                            style={{width: '60px'}} 
                                            value={this.state.slippageTolerance} 
                                            onChange={(e) => this.setState({slippageTolerance: e.currentTarget.value})}>
                                      </input> %
                                    </div>
                                  </div>
                                </div>
                              }
                              <div className='d-flex p-3 align-items-center jc-space-between'>
                                <div><h6>🌎 Eco Contribution</h6></div>
                                <div><h5>$0.5 </h5></div>
                              </div>
                              <div className="border-gradient br-30 mb-2">
                                <button className='btn br-30 bg-light-pink w-100 text-white '  onClick={() => this.setState({walletConnected: false})}> 
                                  SWAP
                                </button>
                              </div>
                            </>
                          }
                        </div>
                      </div>
                      {this.state.walletConnected === true &&
                        <div className="br-10 bg-grey w-100 p-3 d-flex align-items-center jc-space-between">
                          <div className="d-flex align-items-center">
                            <img alt="" src='/images/swap/USDC.png' style={{width: '40px', height: '40px', borderRadius: '50%'}}></img>
                            <div className='ml-3 mb-0 mr-2 text-white text-left'>
                              <div className='d-flex align-items-center'><h5 className='mb-0 mr-2'>USDC </h5> <span style={{fontSize: '12px'}}>0.0%</span></div>
                              
                              <span>0.0</span>
                            </div>
                          </div>
                          <div className=''>
                            <img alt="" src='/images/swap/graphAzul.png'></img>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </Zoom>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default Converter
