import React from "react";
import Zoom from "react-reveal/Reveal";

class Minter extends React.Component {
  componentDidMount() {}

  state = {
    walletConnected: false,
    fromValue: "0.0",
    toValue: "0.0",
    manageTolerance: false,
    slippageTolerance: "1",
  };

  render() {
    return (
      <section className="section Minter m-0 p-0" id="Minter">
        <div className="black-layer-2">
          <div className="container mt-2 mb-3 ptb-12">
            <div className="row align-items-center justify-content-center mt-4">
              <Zoom bottom>
                <div className="col-lg-6 col-md-8 col-sm-12 text-white">
                  <h1 className="main-title-3">Proof of Impact NFT Mint</h1>
                  <h6>
                    An easy way to document your contribution to the Planet
                  </h6>
                  <br />
                  <div
                    className="bg-grey w-100 br-10 mb-3"
                    style={{ padding: "40px 80px" }}
                  >
                    <h2>Before Cleaning</h2>
                    <h5>PNG, JPG, GIF, Up to MP3</h5>
                    <div className="border-gradient-green btn-rounded w-auto mt-4">
                      <button className="btn br-30 btn-rounded bg-light-black w-100 text-white ff-monte">
                        Choose File
                      </button>
                    </div>
                  </div>
                  <div className="form-group text-left">
                    <label>Title</label>
                    <input className="form-control w-100 bg-dark-grey-2 border-grey-2 text-white"></input>
                  </div>
                  <div className="form-group text-left">
                    <label>GPS</label>
                    <input className="form-control w-100 bg-dark-grey-2 border-grey-2 text-white"></input>
                  </div>
                  <div className="form-group text-left">
                    <label>Team Cleaning</label>
                    <input className="form-control w-100 bg-dark-grey-2 border-grey-2 text-white"></input>
                  </div>
                  <div
                    className="bg-grey w-100 br-10 mt-5 mb-3"
                    style={{ padding: "40px 80px" }}
                  >
                    <h2>After Cleaning</h2>
                    <h5>PNG, JPG, GIF, Up to MP3</h5>
                    <div className="border-gradient-green btn-rounded w-auto mt-4">
                      <button className="btn br-30 btn-rounded bg-light-black w-100 text-white ff-monte">
                        Choose File
                      </button>
                    </div>
                  </div>
                  <div className="form-group text-left">
                    <label>Details</label>
                    <input className="form-control w-100 bg-dark-grey-2 border-grey-2 text-white"></input>
                  </div>
                  <div className="form-group text-left">
                    <label>Use of Founds</label>
                    <input className="form-control w-100 bg-dark-grey-2 border-grey-2 text-white"></input>
                  </div>
                  <button className="btn w-100 bg-grey btn-rounded text-white">
                    Advanced Settings
                  </button>
                  <div className="row mt-5">
                    <div className="col-7 fd-column">
                      <div className="d-flex jc-space-between">
                        <h6>Network Fees:</h6>
                        <h6>$1.72</h6>
                      </div>
                      <div className="d-flex jc-space-between">
                        <h6>Storage Fees:</h6>
                        <h6>$0.01</h6>
                      </div>
                      <hr className="text-white bg-white" />
                      <div className="d-flex jc-space-between">
                        <h6>Total Fee:</h6>
                        <h6>$1.71</h6>
                      </div>
                    </div>
                    <div className="col-5">
                      <h6>Impact Pafrtners Logo</h6>
                      <div className="bg-dark-grey w-100 br-20 p-4 border-grey">
                        <h5 className="fc-gradient-green">
                          Click here to upload logo
                        </h5>
                        <p>PNG, JPG, SVG Up to 2mb</p>
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="border-gradient-green btn-rounded">
                        <button className="btn btn-rounded bg-blue w-100 text-white">
                          CREATE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Zoom>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Minter;
