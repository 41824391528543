import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faMinusCircle,
  faStar,
  faSearch,
  faCaretDown,
  faTimesCircle,
  faChevronDown,
  faEllipsisV,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import first from "../../../../assets/img/ALPHATRADE/first.jpeg";
import second from "../../../../assets/img/ALPHATRADE/second.jpeg";
import third from "../../../../assets/img/ALPHATRADE/third.jpeg";
import soldierImg from "../../../../assets/img/ALPHATRADE/bottomSoldierImage.jpeg";
import balance from "./icons/balance.svg";
import "./style.css";
import { bancambios_order_Book } from "../../../../assets/data";

export default function AlphatradeRight() {
  const [monitoringImg, setMonitoringImg] = useState(first);
  const [roundBorders, setRoundBorders] = useState("first");
  return (
    <section className="right_trading_section">
      <p style={{ color: "#0058ff", textAlign: "center" }}>
        <img
          src={balance}
          style={{ color: "#0058ff", width: 15, marginRight: 5, verticalAlign: "inherit" }}
          alt="balance"
        />
        Balance/ Funds Wallet/ Transfers
      </p>

      <h3 style={{ textAlign: "center" }}>Bancambios order Book</h3>
      <span style={{ textAlign: "center" }}>Markets/ Pairs</span>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p
          className="buy_sell_btn"
          style={{
            backgroundColor: "#2abb00",
          }}
        >
          <span style={{ fontWeight: 700 }}>BUY</span>
          <span style={{ float: "right", fontWeight: 700, paddingRight: 5 }}>{">"}</span>
        </p>
        <div
          style={{
            display: "flex",
            marginLeft: 10,
            marginRight: 10,
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon
            icon={faPlusCircle}
            style={{ background: "white", border: "0px solid transparent", borderRadius: "100%" }}
            color="#2abb00"
          />
          <input
            style={{
              marginLeft: 10,
              marginRight: 10,
              backgroundColor: "transparent",
              textAlign: "center",
              border: "2px solid grey",
              width: 80,
            }}
            type="text"
            placeholder="0"
          />
          <FontAwesomeIcon
            icon={faMinusCircle}
            style={{ background: "white", border: "0px solid transparent", borderRadius: "100%" }}
            color="#ff0058"
          />
        </div>
        <p
          className="buy_sell_btn"
          style={{
            backgroundColor: "#ff0058",
          }}
        >
          <span style={{ fontWeight: 700 }}>SELL</span>
          <span style={{ float: "right", fontWeight: 700, paddingRight: 5 }}>{">"}</span>
        </p>
      </div>

      <div className="tr_section">
        <div className="top_one">
          <p>
            <FontAwesomeIcon icon={faStar} color="white" />
          </p>
          <p>M</p>
          <p>BX</p>
          <p>BTC</p>
          <p>ALTS</p>
          <p>FIAT</p>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", marginTop: 5 }}>
          <div style={{ border: "1px solid #b9b9b9", flex: 1, maxWidth: "40%" }}>
            <FontAwesomeIcon icon={faSearch} style={{ marginLeft: 5 }} color="white" />
            <input
              style={{
                backgroundColor: "black",
                color: "#b9b9b9",
                maxWidth: "70%",
                paddingLeft: 3,
                marginTop: 3,
                border: "none",
              }}
              placeholder="Search"
              type="text"
            />
          </div>
          <div></div>
          <input style={{ marginTop: 5 }} type="radio" id="change_order_book" name="change" />
          <label htmlFor="change_order_book">Change </label>
          <input style={{ marginTop: 5 }} type="radio" id="volume_order_book" name="volume" />
          <label htmlFor="volume_order_book">Volume </label>
        </div>

        <div style={{ maxHeight: 200, overflowY: "auto" }}>
          <table style={{ width: "97%" }}>
            <thead>
              <tr style={{ color: "#555b6f" }}>
                <th style={{ background: "black", paddingLeft: 25 }}>Pair</th>
                <th style={{ background: "black" }}>Price</th>
                <th style={{ background: "black", textAlign: "right" }}>Change</th>
              </tr>
            </thead>
            <tbody>
              {bancambios_order_Book.map((item) => (
                <tr>
                  <td>
                    <FontAwesomeIcon icon={faStar} style={{ marginRight: 5 }} color="#0058ff" />
                    {item.pair}
                    <span style={{ float: "right", color: "#0058ff", paddingRight: 5 }}>{item.mult}</span>
                  </td>
                  <td>{item.price}</td>
                  <td style={{ fontWeight: 700, textAlign: "right", color: item.change > 0 ? "#0058ff" : "#2abb00" }}>
                    {item.change}%
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="grey_btnss_con">
        <div style={{ width: "100%" }}>$4678.08</div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "transparent",
            padding: 0,
            margin: 0,
          }}
        >
          <div style={{ flex: 1, width: "80%", padding: "8px 10px" }}>
            Social Charting Analysis
            <FontAwesomeIcon style={{ float: "right", marginTop: 3 }} icon={faCaretDown} />
          </div>
          <div style={{ float: "right", marginLeft: 10, padding: 10 }}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </div>
        </div>
      </div>

      <img
        src={monitoringImg}
        style={{ border: "1px solid white", width: "100%", height: 170, marginTop: 10 }}
        alt=""
      />

      <p
        style={{
          backgroundColor: "#0c152d",
          borderRadius: 8,
          padding: "3px 10px",
          margin: "10px auto",
          textAlign: "center",
        }}
      >
        Customize Monitoring
      </p>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <img
          src={first}
          style={{
            border: roundBorders === "first" ? "4px solid  #0058ff" : "none",
            width: 100,
            height: 60,
            marginTop: 10,
            borderRadius: 10,
          }}
          alt=""
          onClick={() => {
            setMonitoringImg(first);
            setRoundBorders("first");
          }}
        />

        <img
          src={second}
          style={{
            border: roundBorders === "second" ? "4px solid  #0058ff" : "none",
            width: 100,
            height: 60,
            marginTop: 10,
            borderRadius: 10,
          }}
          alt=""
          onClick={() => {
            setMonitoringImg(second);
            setRoundBorders("second");
          }}
        />

        <img
          src={third}
          style={{
            border: roundBorders === "third" ? "4px solid  #0058ff" : "none",
            width: 100,
            height: 60,
            marginTop: 10,
            borderRadius: 10,
          }}
          alt=""
          onClick={() => {
            setMonitoringImg(third);
            setRoundBorders("third");
          }}
        />
      </div>

      <div className="blu_btn_con">
        <p>
          <FontAwesomeIcon style={{ float: "left", marginTop: 5 }} icon={faChevronDown} />
          Sentiment Chat / Choose Market
          <FontAwesomeIcon style={{ float: "right", marginTop: 5 }} icon={faEllipsisV} />
        </p>
      </div>

      <div
        style={{
          width: "100%",
          height: 200,
          marginTop: 10,
          backgroundImage: `url(${soldierImg})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          textAlign: "center",
        }}
      >
        <FontAwesomeIcon
          icon={faPlayCircle}
          style={{ marginTop: 60, borderRadius: "100%", background: "white" }}
          size="4x"
          color="#0058ff"
        />
      </div>
      <p className="al_tech_dp">Technical Trend Scanner</p>
      <img alt="" src={require("../../../../assets/img/ALPHATRADE/Technical.jpeg").default} className="al_tech_img" />
    </section>
  );
}
