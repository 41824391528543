import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowMinimize } from "@fortawesome/free-regular-svg-icons";
import {
  faCrosshairs,
  faMousePointer,
  faPaintBrush,
  faFont,
  faDrawPolygon,
  // faCircle,
  faDraftingCompass,
  faMagnet,
  faSearchPlus,
  faSearchMinus,
  faCodeBranch,
  faQuidditch,
} from "@fortawesome/free-solid-svg-icons";
const VerticleToolsBar = () => {
  const verticleTools = {
    upperTools: [
      { icon: faCrosshairs },
      { icon: faPaintBrush },
      { icon: faMousePointer },
      { icon: faFont },
      { icon: faCrosshairs },
      { icon: faDrawPolygon },
      { icon: faDraftingCompass },
      { icon: faWindowMinimize },
    ],
    middleTools: [{ icon: faMagnet }, { icon: faCrosshairs }],
    bottomTools: [
      { icon: faSearchPlus },
      { icon: faSearchMinus },
      { icon: faCodeBranch },
      { icon: faQuidditch },
      { icon: faCrosshairs },
      { icon: faCrosshairs },
      { icon: faCrosshairs },
      { icon: faCrosshairs },
    ],
  };
  return (
    <section style={{ display: "flex", flexDirection: "column" }}>
      <p style={{ color: "#0058ff" }}>Tools</p>
      <div className="toolbox">
        <div style={{ borderBottom: "1px solid #969090", paddingBottom: 3, marginBottom: 3 }}>
          {verticleTools.upperTools.map((item) => (
            <p>
              <FontAwesomeIcon style={{ fontWeight: "lighter", fontSize: 24, margin: "5px 10px" }} icon={item.icon} />
            </p>
          ))}
        </div>
        <div style={{ borderBottom: "1px solid #969090", paddingBottom: 3, marginBottom: 3 }}>
          {verticleTools.middleTools.map((item) => (
            <p>
              <FontAwesomeIcon style={{ fontWeight: "lighter", fontSize: 24, margin: "5px 10px" }} icon={item.icon} />
            </p>
          ))}
        </div>
        <div style={{ borderBottom: "1px solid #969090", paddingBottom: 3, marginBottom: 3 }}>
          {verticleTools.bottomTools.map((item) => (
            <p>
              <FontAwesomeIcon style={{ fontWeight: "lighter", fontSize: 24, margin: "5px 10px" }} icon={item.icon} />
            </p>
          ))}
        </div>
      </div>
    </section>
  );
};

export default VerticleToolsBar;
