import React from "react";
import { Link } from "react-router-dom";
// import Zoom from 'react-reveal/Reveal'

class Footer extends React.Component {
  componentDidMount() {
    document.querySelectorAll(".footer-nav").forEach((el) => {
      el.addEventListener("click", function (e) {
        const id = e.target.getAttribute("data-id");

        // Ocultar todos nuevamente
        document.querySelectorAll(`.footer-list`).forEach((el) => {
          if (el.getAttribute("id") !== id) el.classList.remove("show");
        });

        // Mostrar el indicado
        document.querySelector(`#${id}`).classList.toggle("show");
      });
    });
  }

  render() {
    return (
      <footer className="footer">
        <h3 className="text-center ff-monte">
          Built with ❤️ and creativity around the 🌎
        </h3>
        <div className="row footer-content">
          <div className="col-7 col-md-2 align-self-center m-auto">
            <img src="/images/intro.gif" alt="Logo" />
          </div>

          <div className="col-12 col-md-7 mt-3 mt-md-0">
            <div className="row">
              <div className="col-3 col-md-3">
                <h4 className="footer-nav" data-id="bancambios">
                  Bancambios
                </h4>
              </div>
              <div className="col-3 col-md-3">
                <h4 className="footer-nav" data-id="information">
                  Information
                </h4>
              </div>
              {/* <div className="col-3 col-md-2">
                <h4 className="footer-nav" data-id="support">
                  Support
                </h4>
              </div> */}
              <div className="col-3 col-md-4">
                <h4 className="footer-nav" data-id="contact">
                  Contact
                </h4>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 mt-3 mt-md-0">
                <ul className="list-unstyled footer-list" id="bancambios">
                  <li>
                    <Link to="#">Home</Link>
                  </li>
                  <li>
                    <Link to="#">Novobook</Link>
                  </li>
                  <li>
                    <Link to="#">Alpatrade</Link>
                  </li>
                  <li>
                    <Link to="#">Catapult</Link>
                  </li>
                  <li>
                    <Link to="/baft">BX Token</Link>
                  </li>
                  <li>
                    <Link to="/baft">NFT</Link>
                  </li>
                </ul>
              </div>

              <div className="col-md-3">
                <ul className="list-unstyled footer-list" id="information">
                  <li>
                    <Link to="#">Terms & Condition</Link>
                  </li>
                  <li>
                    <a href="https://bancambios.com/about/">About us</a>
                  </li>
                  <li>
                    <Link to="#">Privacy</Link>
                  </li>
                </ul>
              </div>

              {/* <div className='col-md-2'>
                <ul className='list-unstyled footer-list' id='support'>
                  <li>
                    <Link to='#'>FAQ</Link>
                  </li>
                  <li>
                    <Link to='#'>Contact</Link>
                  </li>
                </ul>
              </div> */}

              <div className="col-md-4">
                <ul className="list-unstyled footer-list" id="contact">
                  <li>
                    <Link to="#">info@bancambios.exchange</Link>
                  </li>
                  <li>
                    <Link to="#">business@bancambios.exchange</Link>
                  </li>
                  <li>
                    <Link to="#">support@bancambios.exchange</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-3 mt-3 mt-md-0">
            <form action="#" className="footer-form pr-md-5">
              <h4 className="mb-2">Subscribe</h4>
              <input
                type="text"
                name="name"
                placeholder="Name"
                className="form-control"
                autoComplete="off"
              />
              <input
                type="email"
                name="email"
                autoComplete="email"
                placeholder="Email"
                className="form-control"
              />
              <input
                type="submit"
                value="Subscribe"
                className="btn btn-shadow"
              />
              <div className="d-flex justify-content-between mt-4 social-media">
                <a href="https://twitter.com/bancambios" target="blank">
                  <img
                    className="socialMedia-icon"
                    src="images/SocialMediaIcons/white/twitter.png"
                    alt=""
                  />
                </a>
                <a href="https://t.me/BancambiosX" target="blank">
                  <img
                    className="socialMedia-icon"
                    src="images/SocialMediaIcons/white/telegram.png"
                    alt=""
                  />
                </a>
                <a href="https://discord.com/invite/BXcommunity" target="blank">
                  <img
                    className="socialMedia-icon"
                    src="images/SocialMediaIcons/white/discord.png"
                    alt=""
                  />
                </a>
                <a href="https://bancambios.medium.com/" target="blank">
                  <img
                    className="socialMedia-icon"
                    src="images/SocialMediaIcons/white/medium.png"
                    alt=""
                  />
                </a>
                {/* <a
                  href="https://bitcointalk.org/index.php?topic=5334267.0"
                  target="blank"
                >
                  <img
                    className="socialMedia-icon"
                    src="images/SocialMediaIcons/bitcoinIcon.png"
                    alt=""
                  />
                </a> */}
                {/* <a
                  href="https://www.youtube.com/channel/UCqEVW72R1T-C2uAEoiFk3KA"
                  target="blank"
                >
                  <img
                    className="socialMedia-icon"
                    src="images/SocialMediaIcons/youtubeIcon.png"
                    alt=""
                  />
                </a> */}
                <a
                  href="https://www.linkedin.com/company/bancambios"
                  target="blank"
                >
                  <img
                    className="socialMedia-icon"
                    src="images/SocialMediaIcons/white/linkedin.png"
                    alt=""
                  />
                </a>
                <a href="https://www.facebook.com/bancambios" target="blank">
                  <img
                    className="socialMedia-icon"
                    src="images/SocialMediaIcons/white/facebook.png"
                    alt=""
                  />
                </a>
                <a href="https://www.instagram.com/bancambiosx/" target="blank">
                  <img
                    className="socialMedia-icon"
                    src="images/SocialMediaIcons/white/instagram.png"
                    alt=""
                  />
                </a>
              </div>
            </form>
          </div>
        </div>

        {/* <p className="copy-rights text-white text-center poppins mt-3">
          Copyright © Bancambios - {new Date().getFullYear()}. All Rights
          Reserved
        </p> */}
      </footer>
    );
  }
}
export default Footer;
