import React from 'react';
import './styles.css'

const BuyBar = () => {
  return (
      <div class="container">

        <input type="radio" class="radio" name="buy" value="zero" id="zero-buy"/>
        <label for="zero-buy" class="label">0%</label>

        <input type="radio" class="radio" name="buy" value="twentyfive" id="twentyfive-buy" />
        <label for="twentyfive-buy" class="label">25%</label>

        <input type="radio" class="radio" name="buy" value="fifty" id="fifty-buy" />
        <label for="fifty-buy" class="label">50%</label>

        <input type="radio" class="radio" name="buy" value="seventyfive" id="seventyfive-buy" />
        <label for="seventyfive-buy" class="label">75%</label>

        <input type="radio" class="radio" name="buy" value="onehundred" id="onehundred-buy" />
        <label for="onehundred-buy" class="label">100%</label>

        <div class="buy-progress">
          <div class="buy-progress-bar"></div>
        </div>
      </div>
  );
};

export default BuyBar;