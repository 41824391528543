import React from "react";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import Aux from "./hoc/Aux_";

const Layout = ({ children }) => {
  return (
    <div>
      <Aux>
        <Navbar />
        {children}
        <Footer />
      </Aux>
    </div>
  );
};

export default Layout;
