import wave_energy from "./img/wave_energy.jpg";
import hydroponicsBatrounAgricultu from "./img/hydroponicsBatrounAgricultu.jpg";
import photo_2020 from "./img/photo_2020.jpg";
import Datacenterpark from "./img/Datacenterpark.jpg";
import R1_driving_on_public_road from "./img/R1_driving_on_public_road.png";
import vr_gaming from "./img/vr_gaming.jpg";
import AquaPonic from "./img/AquaPonic.png";
import cementactory from "./img/cementactory.png";
import greenarchitecture from "./img/greenarchitecture.jpg";
import catapulDltImg1 from "./img/catapult-dlt-1.png";
import catapulDltImg2 from "./img/catapult-dlt-2.png";
import catapulDltImg3 from "./img/catapult-dlt-3.png";
import catapulDltImg4 from "./img/catapult-dlt-4.png";
import catapulDltImg5 from "./img/catapult-dlt-5.png";
import catapulDltImg6 from "./img/catapult-dlt-6.png";
import catapulDltImg7 from "./img/catapult-dlt-7.png";
import catapulDltImg8 from "./img/catapult-dlt-8.png";
import catapulDltImg9 from "./img/catapult-dlt-9.png";
import catapulDltImg10 from "./img/catapult-dlt-10.png";
import catapulDltImg11 from "./img/catapult-dlt-11.png";
import catapulDltImg12 from "./img/catapult-dlt-12.png";
import catapulDltImg13 from "./img/catapult-dlt-13.png";
import catapulDltImg14 from "./img/catapult-dlt-14.png";
import catapulDltImg15 from "./img/catapult-dlt-15.png";
import catapulDltImg16 from "./img/catapult-dlt-16.png";
import catapulDltImg17 from "./img/catapult-dlt-17.png";
import catapulDltImg18 from "./img/catapult-dlt-18.png";

export const data = [
  {
    id: "1",
    name: "Operating Wave Energy Farm",
    description: "Tokenized shares of energy company",

    img: wave_energy,
  },
  {
    id: "2",
    name: "Vertical Farm Company",
    description: "Co-own organic food producer",
    img: hydroponicsBatrounAgricultu,
  },
  {
    id: "3",
    name: "Bio-Plastic Production",
    description: "Petrol-free Plastic-like Factory",
    img: photo_2020,
  },
  {
    id: "4",
    name: "Data Center Storage Facility",
    description: "Co-own a long term leased property",
    img: Datacenterpark,
  },
  {
    id: "5",
    name: "Electric self-drive Delivery Car",
    description: "Autonomous Car generating Profits",
    img: R1_driving_on_public_road,
  },
  {
    id: "6",
    name: "Online VR Gaming Company",
    description: "Operating energy company shares",
    img: vr_gaming,
  },
  {
    id: "7",
    name: "AquaPonic Farm",
    description: "Co-ownership of AquaPonic Farm Production of Quality Food",
    img: AquaPonic,
  },
  {
    id: "8",
    name: "Ecologic Cement Factory",
    description: "Own Bancambios Digital Shares",
    img: cementactory,
  },
  {
    id: "9",
    name: "Pandemia-resistance Real Estate",
    description: "Sustainable Working Buildings",
    img: greenarchitecture,
  },
];

export const catapultDltData = [
  { name: "TradingView", img: catapulDltImg1 },
  { name: "Seeking Aplha", img: catapulDltImg2 },
  {
    name: "Disrupt Network",
    img: catapulDltImg3,
  },
  { name: "Coin Factory", img: catapulDltImg4 },
  { name: "Chain X", img: catapulDltImg5 },
  { name: "BitMart", img: catapulDltImg6 },
  { name: "Minds", img: catapulDltImg7 },
  { name: "thecapital", img: catapulDltImg8 },
  { name: "Polkastarter", img: catapulDltImg9 },
  { name: "ADVFN", img: catapulDltImg10 },
  { name: "ITSA", img: catapulDltImg11 },
  { name: "INATBA", img: catapulDltImg12 },
  { name: "IBC", img: catapulDltImg13 },
  { name: "GDF", img: catapulDltImg14 },
  { name: "UNISWAP", img: catapulDltImg15 },
  { name: "p2p P b2b", img: catapulDltImg16 },
  { name: "KuCoin", img: catapulDltImg17 },
  { name: "gate.io", img: catapulDltImg18 },
];

export const bancambios_order_Book = [
  { pair: "ADA/BTC", mult: "3x", price: 0.0000019, change: -1.5 },
  { pair: "ADX/BTC", mult: "", price: 0.0000231, change: -3.81 },
  { pair: "AE/BTC", mult: "", price: 0.0001329, change: -2.9 },
  { pair: "AGI/BTC", mult: "", price: 0.0023419, change: -9.98 },
  { pair: "AION/BTC", mult: "", price: 0.0051209, change: -1.34 },
  { pair: "ALGO/BTC", mult: "", price: 0.0000019, change: 1.69 },
  { pair: "AMB/BTC", mult: "", price: 0.0000023, change: -2.85 },
  { pair: "ANKR/BTC", mult: "", price: 0.0000125, change: 1.57 },
  { pair: "ANT/BTC", mult: "", price: 0.0000234, change: -4.75 },
  { pair: "APPC/BTC", mult: "", price: 0.0000019, change: -3.72 },
  { pair: "ARDR/BTC", mult: "", price: 0.0000231, change: -1.74 },
  { pair: "ADX/BTC", mult: "", price: 0.0001329, change: -8.56 },
  { pair: "AE/BTC", mult: "2x", price: 0.0023419, change: 4.5 },
  { pair: "AGI/BTC", mult: "", price: 0.0051201, change: -1.5 },
  { pair: "AION/BTC", mult: "", price: 0.0000019, change: -3.81 },
  { pair: "ALGO/BTC", mult: "", price: 0.0000023, change: -2.9 },
  { pair: "AMB/BTC", mult: "", price: 0.0000125, change: 9.98 },
  { pair: "ANKR/BTC", mult: "", price: 0.0000234, change: -1.34 },
  { pair: "ANT/BTC", mult: "", price: 0.0000019, change: -1.69 },
  { pair: "APPC/BTC", mult: "", price: 0.0000231, change: -2.85 },
  { pair: "ARDR/BTC", mult: "", price: 0.0001329, change: -1.57 },
  { pair: "ADX/BTC", mult: "4x", price: 0.0002341, change: 4.75 },
  { pair: "AE/BTC", mult: "", price: 0.0012019, change: -3.72 },
  { pair: "AGI/BTC", mult: "", price: 0.0000019, change: -1.74 },
  { pair: "AION/BTC", mult: "", price: 0.0000023, change: -1.5 },
  { pair: "ALGO/BTC", mult: "", price: 0.0000125, change: 3.81 },
  { pair: "AMB/BTC", mult: "", price: 0.0000234, change: -2.9 },
  { pair: "ANKR/BTC", mult: "", price: 0.0000019, change: -9.98 },
  { pair: "ANT/BTC", mult: "", price: 0.0000231, change: -1.34 },
  { pair: "APPC/BTC", mult: "", price: 0.0001329, change: -1.69 },
  { pair: "ARDR/BTC", mult: "", price: 0.0023419, change: -2.85 },
  { pair: "ADC/BTC", mult: "", price: 0.0052019, change: -1.57 },
  { pair: "ABT/BTC", mult: "", price: 0.0000019, change: -4.75 },
  { pair: "ALA/BTC", mult: "2x", price: 0.0000023, change: -3.72 },
  { pair: "ANC/BTC", mult: "", price: 0.0000125, change: -1.74 },
  { pair: "ABC/BTC", mult: "", price: 0.0000234, change: -8.56 },
];

export const recentTradesActiveOrders = [
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
  { rate: 223, amount: 123, time: "02:34" },
];
