import React from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";

import BocaChicaLogo from "../../../assets/img/tokenSale/BocaChicaLogo.svg";
import bullperksstandard from "../../../assets/img/tokenSale/bullperks-standard.svg";
import kaizen from "../../../assets/img/tokenSale/kaizen.png";

function BannerRegister() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <section className="section" id="register">
        <div className="black-layer-gradient">
          <div className="section-content">
            <div className="container">
              <div className="row ">
                <div className="col-md-4 col-4 text-left">
                  <h3 className="token-sale">LAUNCHPAD</h3>
                  {/* <h1>Bancambios Token Sale</h1>
                    <br /> <br></br>
                    <h4>Join the new Standar of Finance Supporting the Environment</h4>
                    <br />
                    <button className="btn btn-info sign-up" onClick={handleShow}>Sign up</button> */}
                </div>
                <div className="col-md-4 col-4 text-left">
                  <h3 className="token-sale">ROUND</h3>
                </div>
                <div className="col-md-4 col-4 text-left">
                  <h3 className="token-sale">COLLECTIBLE NFT</h3>
                </div>
              </div>

              <div className="row token-box">
                <div className="col-md-4 col-4 text-left">
                  <img src={BocaChicaLogo} className="img-fluid" alt="" />
                </div>
                <div className="col-md-4 col-4 text-left">
                  <p>
                    Price: $0.008 USDT / BX <br />
                    Maximum allocation: $250USDT
                    <br /> Funding with USDT & HAPI <br />
                    Size of Round: $180,000 USDT
                  </p>
                </div>
                <div className="col-md-4 col-4 text-left">
                  <img
                    alt=""
                    src="images/token/defi1.gif"
                    className="gif-style"
                  />
                </div>
              </div>
              <div className="row token-box">
                <div className="col-md-4 col-4 text-left">
                  <img src={kaizen} className="img-fluid" alt="" />
                </div>
                <div className="col-md-4 col-4 text-left">
                  <p>
                    Price: $0.008 USDT / BX <br /> Maximum allocation: $250USDT{" "}
                    <br />
                    Funding with USDT <br />
                    Size of Round: $200,000 USDT
                  </p>
                </div>
                <div className="col-md-4 col-4 text-left">
                  <img
                    alt=""
                    src="images/token/defi2.gif"
                    className="gif-style"
                  />
                </div>
              </div>
              <div className="row token-box">
                <div className="col-md-4 col-4 text-left">
                  <img src={bullperksstandard} className="img-fluid" alt="" />
                </div>
                <div className="col-md-4 col-4 text-left">
                  <p>
                    Price: $0.008 USDT / BX <br />
                    Maximum allocation: $300USDT <br /> Funding with USDT <br />
                    Size of Round: $300,000 USDT
                  </p>
                </div>
                <div className="col-md-4 col-4 text-left">
                  <img
                    alt=""
                    src="images/token/defi3.gif"
                    className="gif-style"
                  />
                </div>
              </div>
              <div className="row token-box">
                <div className="col-md-4 col-4 text-left">
                  <img
                    src="images/token/raydium.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-md-4 col-4 text-left">
                  <p>
                    USDT: $75,000 USDT <br /> BX: 7,229,730
                  </p>
                </div>
                <div className="col-md-4 col-4 text-left">
                  <img
                    alt=""
                    src="images/token/defi1.gif"
                    className="gif-style"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal dialogClassName="modal-register" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>REGISTER</Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-register"
            variant="primary"
            onClick={handleClose}
          >
            SIGN UP
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default BannerRegister;
