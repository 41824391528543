export const Routes = [
  { title: "Home", url: "/" },
  { title: "Swap/Trade", url: "/swap" },
  { title: "Liquidity", url: "/liquidity" },
  { title: "Staking", url: "/staking" },
  { title: "Catapult", url: "/catapult" },
  { title: "NFT", url: "/nft" },
  { title: "Metaverse", url: "/metaverse" },
  { title: "DAO", url: "/nft" },
  // { title: "TOKEN SALE", url: "/register" },
];
