import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./style.css";

export default class LeftNav extends Component {
  render() {
    return (
      <div style={styles.wrapper}>
        <NavLink style={styles.navItem} to="!#">
          <img src={require("./icons/exchange.svg").default} style={styles.img} alt="icon" />
          <p>Exchange</p>
        </NavLink>

        <NavLink style={styles.navItem} to="#">
          <img src={require("./icons/trading.svg").default} style={{ width: "30%" }} alt="icon" />
          <p>Trading</p>
        </NavLink>

        <NavLink style={styles.navItem} to="#">
          <img src={require("./icons/funding.svg").default} style={{ width: "30%" }} alt="icon" />
          <p>Funding</p>
        </NavLink>

        <NavLink style={styles.navItem} to="#">
          <img src={require("./icons/reports.svg").default} style={{ width: "30%" }} alt="icon" />
          <p>Reports</p>
        </NavLink>

        <NavLink style={styles.navItem} to="#">
          <img src={require("./icons/balance.svg").default} style={{ width: "30%" }} alt="icon" />
          <p>Balance</p>
        </NavLink>

        <NavLink style={styles.navItem} to="!#">
          <img src={require("./icons/robot.svg").default} style={{ width: "30%" }} alt="icon" />
          <p>Active Robot-Advisor</p>
        </NavLink>

        <NavLink style={styles.navItem} to="#">
          <img src={require("./icons/payments.svg").default} style={{ width: "30%" }} alt="icon" />
          <p>Payments</p>
        </NavLink>

        <NavLink style={styles.navItem} to="#">
          <img src={require("./icons/earn.svg").default} style={{ width: "30%" }} alt="icon" />
          <p>Earn from 3% to 10% interest</p>
        </NavLink>

        <NavLink style={styles.navItem} to="#">
          <img src={require("./icons/markets.svg").default} style={{ width: "30%" }} alt="icon" />
          <p>Markets</p>
        </NavLink>

        <NavLink style={styles.navItem} to="#">
          <img src={require("./icons/follow.svg").default} style={{ width: "30%" }} alt="icon" />
          <p>Follow Pro-Traders</p>
        </NavLink>

        <NavLink style={styles.navItem} to="#">
          <img src={require("./icons/invite.svg").default} style={{ width: "30%" }} alt="icon" />
          <p>Invite friends</p>
        </NavLink>

        <NavLink style={styles.navItem} to="#">
          <img src={require("./icons/contact_tech.svg").default} style={{ width: "30%" }} alt="icon" />
          <p>Contact Tech</p>
        </NavLink>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    width: 250,
    height: "inherit",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "repeat(6, 1fr)",
  },
  navItem: {
    backgroundColor: "#050d22",
    margin: 0.6,
    color: "#8e8e8f",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    textAlign: "center",
    fontSize: 14,
  },
  img: {
    width: "30%",
  },
};
