import React from "react";
import Slide from "react-reveal/Slide";

class Advisors extends React.Component {
  render() {
    return (
      <>
        <section className="section pt-4 advisors" id="advisors">
          <Slide bottom cascade>
            <div className="container px-0">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <h1 className="text-center ff-monte main-title-3">
                    Advisors
                  </h1>
                  <div className="section-title-border margin-t-20"></div>
                  {/*<p className="section-subtitle text-muted text-center font-secondary padding-t-30">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>*/}
                </div>
              </div>
              <div className="row pb-4 justify-content-center">
                <Slide bottom cascade>
                  <div className="col-lg-3 col-sm-6 mt-5  hover-effect text-center">
                    <div className="advisor-card bg-dark">
                      <img
                        alt=""
                        src="images/advisors/Joe.jpg"
                        className="img-fluid rounded circle pink"
                        width="100%"
                      />
                      <br />
                      <h5 className="text-white mt-4">Joe Vezzani</h5>
                      <span>CEO Lunar Crush </span>
                      <br />
                      <br />
                      <div className="advisor-social text-white mt-2">
                        <a
                          className="socialMediaIcon"
                          href="https://www.linkedin.com/in/joevezzani/"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                        {/* <a className="socialMediaIcon" href="https://www.facebook.com/bancambios" target="blank">
                          <i className="fab fa-facebook text-blue" />
                        </a> */}
                        <a
                          className="socialMediaIcon"
                          href="https://twitter.com/lunarcrush"
                          target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </Slide>
                <Slide bottom cascade>
                  <div className="col-lg-3 col-sm-6 mt-5  hover-effect text-center">
                    <div className="advisor-card bg-dark">
                      <img
                        alt=""
                        src="images/team/sh.jpeg"
                        className="img-fluid rounded circle pink"
                        width="100%"
                      />
                      <br />
                      <h5 className="text-white mt-4">Schwarze Kat</h5>
                      <span>DeFi Engineer </span>
                      <br />
                      <br />
                      <div className="advisor-social text-white mt-2">
                        {/* <a
                          className="socialMediaIcon"
                          href="https://www.linkedin.com/in/joevezzani/"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a> */}
                        {/* <a className="socialMediaIcon" href="https://www.facebook.com/bancambios" target="blank">
                          <i className="fab fa-facebook text-blue" />
                        </a> */}
                        <a
                          className="socialMediaIcon"
                          href="https://twitter.com/DSchwarzeKatze"
                          target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </Slide>
                <Slide bottom cascade>
                  <div className="col-lg-3 col-sm-6 mt-5  hover-effect text-center">
                    <div className="advisor-card bg-dark">
                      <img
                        alt=""
                        src="images/advisors/ShirlyValge.jpg"
                        className="img-fluid rounded circle pink"
                        width="100%"
                      />
                      <br />
                      <h5 className="text-white mt-4">Shirly Valge</h5>
                      <span>COO Velas Blockchain</span>
                      <br />
                      <br />
                      <div className="advisor-social text-white mt-2">
                        <a
                          className="socialMediaIcon"
                          href="https://www.linkedin.com/in/shirly-valge/"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                        {/* <a className="socialMediaIcon" href="https://www.linkedin.com/in/daniel-warrick-08b22b169" target="blank">
                          <i className="fab fa-facebook text-blue" />
                        </a> */}
                        <a
                          className="socialMediaIcon"
                          href="https://twitter.com/ShirlyValge"
                          target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </Slide>
                <Slide bottom cascade>
                  <div className="col-lg-3 col-sm-6 mt-5  hover-effect text-center">
                    <div className="advisor-card bg-dark">
                      <img
                        alt=""
                        src="images/advisors/Alex Man.jpg"
                        className="img-fluid rounded circle pink"
                        width="100%"
                      />
                      <br />
                      <h5 className="text-white mt-4">Alex Man</h5>
                      <span>Partner Jun Capital</span>
                      <br />
                      <br />
                      <div className="advisor-social text-white mt-2">
                        <a
                          className="socialMediaIcon"
                          href="https://www.linkedin.com/in/alex-man/"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                        {/* <a className="socialMediaIcon" href="https://www.linkedin.com/in/daniel-warrick-08b22b169" target="blank">
                          <i className="fab fa-facebook text-blue" />
                        </a> */}
                        {/* <a
                          className="socialMediaIcon"
                          href="https://twitter.com/bancambios"
                          target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a> */}
                      </div>
                    </div>
                  </div>
                </Slide>
                <Slide bottom cascade>
                  <div className="col-lg-3 col-sm-6 mt-5  hover-effect text-center">
                    <div className="advisor-card bg-dark">
                      <img
                        alt=""
                        src="images/advisors/MarcelloMari.jpg"
                        className="img-fluid rounded circle pink"
                        width="100%"
                      />
                      <br />
                      <h5 className="text-white mt-4">Marcello Mari</h5>
                      <span>CEO Singularity DAO </span>
                      <br />
                      <br />
                      <div className="advisor-social text-white mt-2">
                        <a
                          className="socialMediaIcon"
                          href="https://www.linkedin.com/in/marcellomari/"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                        {/* <a className="socialMediaIcon" href="https://www.linkedin.com/in/daniel-warrick-08b22b169" target="blank">
                          <i className="fab fa-facebook text-blue" />
                        </a> */}
                        <a
                          className="socialMediaIcon"
                          href="https://twitter.com/DotNever"
                          target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </Slide>
                {/* <Slide bottom cascade>
                  <div className="col-lg-2 col-sm-6 mt-5  hover-effect text-center">
                    <div className="advisor-card bg-dark">
                      <img alt="" src="images/advisors/EvgenVerzun.jpeg" className="img-fluid rounded circle pink" width="100%" />
                      <br />
                      <h5 className="text-white mt-4">Evgen Verzun</h5>
                      <span>Co-Founder - Kaizen Finance</span>
                      <br/>
                      <div className="advisor-social text-white mt-2">
                        <a className="socialMediaIcon" href="https://www.linkedin.com/in/verzun" target="blank">
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                        {/* <a className="socialMediaIcon" href="https://www.linkedin.com/in/daniel-warrick-08b22b169" target="blank">
                          <i className="fab fa-facebook text-blue" />
                        </a> */}
                {/* <a className="socialMediaIcon" href="https://twitter.com/Evgen_AI" target="blank">
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </Slide> */}{" "}
                <Slide bottom cascade>
                  <div className="col-lg-3 col-sm-6 mt-5  hover-effect text-center">
                    <div className="advisor-card bg-dark">
                      <img
                        alt=""
                        src="images/advisors/BilalSqib.png"
                        className="img-fluid rounded circle pink"
                        width="100%"
                      />
                      <br />
                      <h5 className="text-white mt-4">Bilal Saqib</h5>
                      <span>Social Entrepreneur </span>
                      <br />
                      <br />
                      <div className="advisor-social text-white mt-2">
                        <a
                          className="socialMediaIcon"
                          href="https://www.linkedin.com/in/bilal-bin-saqib-33521b33/"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                        {/* <a className="socialMediaIcon" href="https://www.facebook.com/bancambios" target="blank">
                          <i className="fab fa-facebook text-blue" />
                        </a> */}
                        <a
                          className="socialMediaIcon"
                          href="https://twitter.com/Bilalbinsaqib"
                          target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </Slide>
              </div>
            </div>
          </Slide>
        </section>
        {/* <section className="section pt-4 advisors" id="advisors">
          <Slide bottom cascade>
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <h1 className="text-center ff-monte main-title-3">Backers</h1>
                  <div className="section-title-border margin-t-20"></div>
                  <p className="section-subtitle text-muted text-center font-secondary padding-t-30">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
              </div>
              <div className="row pb-4 justify-content-center">
                <Slide bottom cascade>
                  <div className="col-lg-3 col-sm-6 mt-5  hover-effect text-center">
                    <div className="advisor-card bg-dark">
                      <div className="backers-bg bg-black">
                        <img
                          alt=""
                          src="images/advisors/bgBlack.png"
                          className="img-fluid square"
                          width="100%"
                        />
                      </div>
                      <h4 className="text-white mt-4">TBA</h4>
                      <span>Bancambio's Backers </span>
                      <div className="advisor-social text-white mt-2">
                        <a
                          className="socialMediaIcon"
                          href="https://www.linkedin.com/company/bancambios"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                        <a className="socialMediaIcon" href="https://www.facebook.com/bancambios" target="blank">
                          <i className="fab fa-facebook text-blue" />
                        </a>
                        <a
                          className="socialMediaIcon"
                          href="https://twitter.com/bancambios"
                          target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </Slide>
                <Slide bottom cascade>
                  <div className="col-lg-3 col-sm-6 mt-5  hover-effect text-center">
                    <div className="advisor-card bg-dark">
                      <div className="backers-bg bg-black">
                        <img
                          alt=""
                          src="images/advisors/bgBlack.png"
                          className="img-fluid  square"
                          width="100%"
                        />
                      </div>
                      <h4 className="text-white mt-4">TBA</h4>
                      <span>Bancambio's Backers </span>
                      <div className="advisor-social text-white mt-2">
                        <a
                          className="socialMediaIcon"
                          href="https://www.linkedin.com/company/bancambios"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                        <a className="socialMediaIcon" href="https://www.facebook.com/bancambios" target="blank">
                          <i className="fab fa-facebook text-blue" />
                        </a>
                        <a
                          className="socialMediaIcon"
                          href="https://twitter.com/bancambios"
                          target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </Slide>
                <Slide bottom cascade>
                  <div className="col-lg-3 col-sm-6 mt-5  hover-effect text-center">
                    <div className="advisor-card bg-dark">
                      <div className="backers-bg bg-black">
                        <img
                          alt=""
                          src="images/advisors/bgBlack.png"
                          className="img-fluid square"
                          width="100%"
                        />
                      </div>
                      <h4 className="text-white mt-4">TBA</h4>
                      <span>Bancambio's Backers. </span>
                      <div className="advisor-social text-white mt-2">
                        <a
                          className="socialMediaIcon"
                          href="https://www.linkedin.com/company/bancambios"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                        <a className="socialMediaIcon" href="https://www.facebook.com/bancambios" target="blank">
                          <i className="fab fa-facebook text-blue" />
                        </a>
                        <a
                          className="socialMediaIcon"
                          href="https://twitter.com/bancambios"
                          target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </Slide>
              </div>
            </div>
          </Slide>
        </section> */}
      </>
    );
  }
}
export default Advisors;
