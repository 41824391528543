import React, { Fragment } from "react";
import Banner from "../../Components/Banner";
import Mechanisms from "../../Components/Mechanisms";
import Products from "../../Components/Products";
import Build from "../../Components/Build";
import Partners from "./Components/Partners";
import DAO from "./Components/DAO";
import Team from "./Components/Team";
import Advisors from "./Components/Advisors";
import Roadmap from "./Components/Roadmap";
import Modal from "./Components/Modal";
// import Description from "./Components/Description";
// import Protectors from './Components/Protectors';
// import Tokenomics from './Components/Tokenomics';
// import Associations from "./Components/Associations";
// import Economics from "./Components/Economics";
// import Characteristics from "./Components/Characteristics";
// import Uses from "./Components/Uses";
// import Video from "./Components/Video";
// import Media from "./Components/Media";
// import Market from "./Components/Market";
// import Experience from "./Components/Experience";

const Baft = () => {
  return (
    <Fragment>
      {/* <Modal /> */}
      <Banner />
      {/* <Mechanisms /> */}
      {/* <Products /> */}
      <Build />
      {/* <Offer /> */}
      <Partners />
      {/* <Protectors /> */}
      {/* <Tokenomics /> */}
      <DAO />
      <Team />
      <Advisors />
      {/* <Roadmap /> */}
    </Fragment>
  );
};

export default Baft;
