import React from "react";
import Slide from "react-reveal/Slide";

import Rotate from "react-reveal/Rotate";
import Zoom from "react-reveal/Reveal";

import solanaLogoHorizontal from "../../../assets/img/solana_logo_horizontal.png";

class DAO extends React.Component {
  render() {
    return (
      <>
        {/* <section className="section pt-4 DAO" id="DAO">
          <Zoom bottom>
            <Slide left>
              <h1 className="text-center ff-monte text-uppercase main-title-3 mb-4">
                DAO
              </h1>
            </Slide>

            <div className="row align-items-center justify-content-center ">
              <div className="borderChain">
                <div className="col-8 col-md-8 mt-5 mt-md-0">
                  <img
                    src="/images/dao/DAONew.png"
                    className="imagesize"
                    alt=""
                  ></img>
                </div>
                <div className="col-4 col-md-4 reasons">
                  <div className="mb-3 mb-md-5">
                    <p>On-chain Capital Formation</p>
                  </div>
                  <div className="mb-3 mb-md-5 ml-3 ">
                    <p>Delegated Investment Strategies</p>
                  </div>

                  <div className="mb-3 mb-md-5 ml-5">
                    <p>Algorythmic Trading Vaults</p>
                  </div>
                  <div className="mb-3 mb-md-5 ml-3">
                    <p>Decentralized Web3 VC Vault</p>
                  </div>
                  <div className="mb-3 mb-md-5">
                    <p>BX Treasury Management</p>
                  </div>
                </div>
              </div>
            </div>
          </Zoom>
        </section> */}
        <section className="section pt-5 DAO" id="DAO">
          <Slide bottom cascade>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h1 className="text-center ff-monte main-title-3 text-white">
                    NFT
                  </h1>
                  <h2 className="third-title fc-white">
                    Documenting "proof of impact" through NFT
                  </h2>
                  <br />
                  <img src="/images/dao/impactGroup.png" alt=""></img>
                </div>
              </div>
            </div>
          </Slide>
        </section>
      </>
    );
  }
}
export default DAO;
