import { useState } from "react";
// import { NavLink } from "react-router-dom";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import NavItem from "./NavItem";
import NavList from "./Data.js";

import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AlphaLeftNav = (props) => {
  const [active, setActive] = useState("exchange");

  return (
    <div className="alpha-left-nav">
      <div className="alpha-grid">
        {NavList.map((item) => (
          <NavItem url={item.url} setActive={setActive} active={active} title={item.title} />
        ))}
      </div>

      <div style={{ backgroundColor: " #050d22", padding: 8, marginTop: 2 }}>
        <h3 style={{ fontWeight: "bold", textAlign: "center" }}>Tools</h3>

        <div className="al_btn_out">
          <p>Trading Strategies {"&"} Analysis</p>
          <p>Short {"&"} Long Indexes</p>
          <p>Portfolio Tacking</p>
          <p>Trades Watchlist {"&"} Alerts</p>
        </div>

        <div className="gry_heads">
          <p>Fundamental Analysis</p>
        </div>
        <div className="gry_items">
          <p>Social Trading Indicators</p>
        </div>

        <div className="gry_heads">
          <p>Market Activity</p>
        </div>
        <div className="gry_items">
          <p>Calendar {"&"} Events</p>
          <p>Trading Summary</p>
          <p>Trend Scanner</p>
        </div>

        <div className="gry_heads">
          <p>Technical Indicators</p>
        </div>
        <div className="gry_items">
          <p>RSI Indicators</p>
          <p>Pattern Scan Strategies</p>
          <p>Oscillators</p>
          <p>Volatility Alerts</p>
          <p>Total Value Locked (Defi)</p>
        </div>

        <div className="blu_btn_sign">
          <p style={{ width: "89%" }}>Sign Up Bancambios Academy for Traders</p>
          <FontAwesomeIcon style={{ width: "10%", marginTop: 15 }} icon={faChevronDown} />
        </div>

        <div style={{ color: "#b9b9b9", paddingLeft: 12 }}>
          <p>Money Markets</p>
          <p>DeFi Digital Assets</p>
          <p>Become Pro-Trader</p>
        </div>
      </div>
      {/* <p className="al_tech_dp">Technical Trend Scanner</p>
      <img alt="" src={require("../../../../assets/img/chart_bg.jpg").default} className="al_tech_img" /> */}
    </div>
  );
};
export default AlphaLeftNav;
