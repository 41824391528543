import React, { Fragment } from "react";
import BannerRegister from './Components/BannerRegister'


const Register = () =>{
  return (
    <Fragment>
      <BannerRegister/>
    </Fragment>
  );

};

export default Register;

