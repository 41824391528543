import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

import BuyBar from "./BuyBar";
import SellBar from "./SellBar";

export default function AmountCal() {
  return (
    <div
      style={{
        backgroundColor: "#050d22",
        borderRadius: 8,
        width: "59%",
        marginRight: 7,
        padding: 13,
      }}
    >
      <div style={{ display: "flex", color: "#b9b9b9", justifyContent: "space-between" }}>
        <div style={{ width: "50%", padding: 5 }}>
          <FontAwesomeIcon
            icon={faPlus}
            size="2x"
            color="#2abb00"
            style={{ border: "3px solid #2abb00", borderRadius: "100%", padding: 3 }}
          />
          <span style={{ verticalAlign: "baseline", fontSize: 24, marginLeft: 10 }}>BUY</span>
          <p style={{ float: "right", fontSize: 18, color: "#2abb00", marginTop: 5 }}>$4,568.04</p>
        </div>

        <div style={{ width: "50%", padding: 5 }}>
          <FontAwesomeIcon
            icon={faMinus}
            size="2x"
            color="#ff0058"
            style={{ border: "3px solid #ff0058", borderRadius: "100%", padding: 3 }}
          />
          <span style={{ verticalAlign: "baseline", fontSize: 24, marginLeft: 10 }}>SELL</span>
          <p style={{ float: "right", fontSize: 18, color: "#ff0058", marginTop: 5 }}>$0</p>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          color: "#b9b9b9",
        }}
      >
        <p>Order Type</p>

        <select style={{ backgroundColor: "#050d22", color: "#b9b9b9" }} name="order" id="order">
          <option value="limit">Limit</option>
          <option value="market">Market</option>
          <option value="take_profit">Take-Pprofit at</option>
          <option value="stop_limit">Stop-Limit</option>
          <option value="one_cancel">One Cancel Order(OCO)</option>
        </select>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p>Balance</p>
        <p>0 BTC</p>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <p style={{ flex: 1 }}>Amount</p>
        <input
          style={{
            backgroundColor: "#050d22",
            color: "#b9b9b9",
            border: "1px solid #b9b9b9",
            flex: 4,
            marginLeft: 20,
          }}
          type="text"
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p style={{ flex: 1 }}>Price</p>
        <input
          style={{
            backgroundColor: "#050d22",
            color: "#b9b9b9",
            border: "1px solid #b9b9b9",
            flex: 4,
            marginLeft: 20,
          }}
          type="text"
        />
      </div>

      <div style={{ display: "flex", paddingTop: 10 }}>
        <section
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            paddingRight: 10,
            borderRight: "1px solid #b9b9b9",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p>Total</p>
            <p>0 USDT</p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p>Fees (0.001%)</p>
            <p>0 USDT</p>
          </div>

          <p
            style={{
              backgroundColor: "#2abb00",
              color: "white",
              padding: 7,
              width: "60%",
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center",
              marginTop: 8,
            }}
          >
            BUY<span style={{ float: "right", fontWeight: 800 }}>{">"}</span>
          </p>

          <BuyBar />
        </section>

        <section
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            paddingLeft: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p>Total</p>
            <p>0 USDT</p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p>Fees (0.001%)</p>
            <p>0 USDT</p>
          </div>

          <p
            style={{
              backgroundColor: "#ff0058",
              color: "white",
              padding: 7,
              width: "60%",
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center",
              marginTop: 8,
            }}
          >
            SELL <span style={{ float: "right", fontWeight: 800 }}>{">"}</span>
          </p>

          <SellBar />
        </section>
      </div>
    </div>
  );
}
