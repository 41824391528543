import Rotate from "react-reveal/Rotate";
import Zoom from "react-reveal/Reveal";
import Slide from "react-reveal/Slide";
import solanaLogoHorizontal from "../assets/img/solana_logo_horizontal.png";
import nearLogoHorizontal from "../assets/img/near.png";

const Build = () => {
  return (
    <section className="section build" id="build">
      {/* <div className="black-layer mb-0">
        <div className='col-lg-12 p-0 '>
          <img
            className='img-divider'
            alt=''
            src='images/char2.png'
            width='100%'
          />
        </div>

        <div className="container pb-5">
          <br />
          <br />
          <br />
          <br />
          <Zoom bottom>
            <Slide left>
              <h1 className="text-center ff-monte text-uppercase main-title-3 mb-4">
                Why Near?
              </h1>
            </Slide>

            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-md-5 reasons">
                <Slide left>
                  <div className="mb-3 mb-md-5 d-flex">
                    <img src="/images/arrow-pink.png" alt="Arrow" />
                    <p>Climate-Neutral Layer-1 Blockchain</p>
                  </div>
                </Slide>
                <Slide left>
                  <div className="mb-3 mb-md-5 ml-5 d-flex">
                    <img src="/images/arrow-pink.png" alt="Arrow" />
                    <p>
                      Nightshade sharding mechanism can process more than
                      100,000 transactions per second (TPS)
                    </p>
                  </div>
                </Slide>
                <Slide left>
                  <div className="mb-3 mb-md-5 d-flex">
                    <img src="/images/arrow-pink.png" alt="Arrow" />
                    <p>10 MILLION TRANSACTIONS FOR $10 DOLLARS</p>
                  </div>
                </Slide>
              </div>

              <div className="col-9 col-md-4 mt-5 mt-md-0">
                <div className="row">
                  <div className="col-8 p-0 m-0">
                    <img
                      src="/images/buildon/near-blue-jagged.png"
                      alt="Logo"
                      id="logo1"
                      className="rotate logo1"
                    />
                  </div>
                  <div className="col-4 p-0 m-0 d-flex flex-column justify-content-end">
                    <div className="rustContainer">
                      <img
                        src="/images/buildon/rust-bluewhite-jagged.png"
                        alt="Logo"
                        className="rotateInverse logo2"
                      />
                    </div>
                  </div>
                </div>
                <br /> <br />
                <div className="d-flex justify-content-center">
                  <Rotate top left cascade>
                    <h1 className="poppins main-title-7 font-weight-bold my-auto mr-2">
                      Build on
                    </h1>
                  </Rotate>
                  <img
                    className="solana-logo-horizontal"
                    src={nearLogoHorizontal}
                    alt="Logo Near"
                  ></img>
                </div>
              </div>
            </div>
          </Zoom>
        </div>
      </div>
      <div className="black-layer mb-5">
        <div className="col-lg-12 p-0 ">
          <img
            className="img-divider mt-0"
            alt=""
            src="images/char2.png"
            width="100%"
          />
        </div>

        <div className="container pb-5">
          <br />
          <br />
          <br />
          <br />
          <Zoom bottom>
            <Slide left>
              <h1 className="text-center ff-monte text-uppercase main-title-3 mb-4">
                Why Solana?
              </h1>
            </Slide>

            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-md-5 reasons">
                <Slide left>
                  <div className="mb-3 mb-md-5 d-flex">
                    <img src="/images/arrow-pink.png" alt="Arrow" />
                    <p>
                      "PROOF OF HISTORY" IS 99% MORE ENERGY-EFFICIENT THAN POW
                    </p>
                  </div>
                </Slide>
                <Slide left>
                  <div className="mb-3 mb-md-5 ml-5 d-flex">
                    <img src="/images/arrow-pink.png" alt="Arrow" />
                    <p>
                      IT CAN SURPASS 100,000,000 ON-CHAIN TRANSACTIONS PER
                      SECOND
                    </p>
                  </div>
                </Slide>
                <Slide left>
                  <div className="mb-3 mb-md-5 d-flex">
                    <img src="/images/arrow-pink.png" alt="Arrow" />
                    <p>10 MILLION TRANSACTIONS FOR $10 DOLLARS</p>
                  </div>
                </Slide>
              </div>

              <div className="col-9 col-md-4 mt-5 mt-md-0">
                <div className="row">
                  <div className="col-8 p-0 m-0">
                    <img
                      src="/images/buildon/solana-color-jagged.png"
                      alt="Logo"
                      id="logo1"
                      className="rotate logo1"
                    />
                  </div>
                  <div className="col-4 p-0 m-0 d-flex flex-column justify-content-end">
                    <div className="rustContainer">
                      <img
                        src="/images/buildon/rust-blueblack-jagged.png"
                        alt="Logo"
                        className="rotateInverse logo2"
                      />
                    </div>
                  </div>
                </div>
                <br /> <br />
                <div className="d-flex justify-content-center">
                  <Rotate top left cascade>
                    <h1 className="poppins main-title-7 font-weight-bold my-auto mr-2">
                      Build on
                    </h1>
                  </Rotate>
                  <img
                    className="solana-logo-horizontal"
                    src={solanaLogoHorizontal}
                    alt="Logo Solana"
                  ></img>
                </div>
              </div>
            </div>
          </Zoom>
        </div>
      </div> */}
      <Zoom bottom>
        <div className="w-100 grid-container">
          <img src="/images/general/perspective_grid.png" width="100%" alt="" />
          <div className="text-grid">
            <h1 className="main-title-3 ff-monte">
              DeFi + Artificial Intelligence + Sustainability
            </h1>
          </div>
        </div>
      </Zoom>
    </section>
  );
};

export default Build;
