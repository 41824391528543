import React from "react";
import { NavLink } from "react-router-dom";

const NavItem = ({ url, title, setActive, active }) => {
  const logo = require(`./icons/${url}${active === url ? "_l.svg" : ".svg"}`);
  return (
    <NavLink
      to={`/alphatrade/${url}`}
      onClick={() => setActive(url)}
      activeStyle={{ backgroundColor: "#0058ff", color: "white" }}
    >
      <img src={logo.default} style={{ width: "23%", color: "white" }} alt="icon" />
      <p>{title}</p>
    </NavLink>
  );
};
export default NavItem;
