import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Layout from "./Layout/index";

import Novobook from "./Views/Novobook/Novobook";
import Alphatrade from "./Views/Alphatrade/Alphatrade";
// import Catapult from "./Views/Catapult/Catapult";
import BX from "./Views/BX/BX.jsx";
import Register from "./Views/Register/Register.jsx";
import Nfts from "./Views/NFT/nft";
import MetaVerse from "./Views/MetaVerse/metaverse";
import LogIn from "./Views/SignIn/Login.jsx";
import NotFound from "./Views/NotFound/NotFound";
import CatapultEcoSto from "./Views/Catapult/CatapultEcoSto";
import CatapultDltProjects from "./Views/Catapult/CatapultDltProjects";
import Stableswap from "./Views/Stableswap/Stableswap";

class Root extends React.Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <Layout>
          <span style={{ marginTop: 69, display: "block" }}></span>
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/`} component={BX} />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/novobook`}
              component={Novobook}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/alphatrade/`}
              component={Alphatrade}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/catapult-eco-sto`}
              component={CatapultEcoSto}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/catapult-dlt-projects`}
              component={CatapultDltProjects}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/metaverse`}
              component={MetaVerse}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/nft`}
              component={Nfts}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/register`}
              component={Register}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/swap`}
              component={Stableswap}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/login`}
              component={LogIn}
            />
            <Route path={`${process.env.PUBLIC_URL}/`} component={NotFound} />
          </Switch>
        </Layout>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
