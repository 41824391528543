const NavList = [
  { url: "exchange", title: "Trading" },
  { url: "trading", title: "Earn 7% to 10% on Stable Coins" },
  { url: "funding", title: "Derivatives Contracts" },
  { url: "reports", title: "Portfolio Tracking" },
  { url: "payments", title: "Borrow / Lending" },
  { url: "earn", title: "Earn from 3% to 10% interest" },
  { url: "markets", title: "Launchpad Marketplace" },
  { url: "follow", title: "Auto-Trade Scheduling" },
];

export default NavList;
