// import { Route, NavLink } from "react-router-dom";
import { motion } from "framer-motion";

import "./style.css";
import { catapultDltData } from "../../assets/data";
import { useHistory } from "react-router";

const CatapultDltProjects = ({ match: { path } }) => {
  const history = useHistory();

  const handleChangeSectionClick = () => {
    if (path.includes("eco-sto")) {
      return history.push("/catapult-dlt-projects");
    }

    return history.push("/catapult-eco-sto");
  };

  return (
    <div className="launchpad-container">
      <motion.section
        style={{ paddingTop: 32 }}
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.8, ease: "easeInOut" }}
        className="launchpad-intro">
        <h1>
          Here you can find in-chain projects that passed a rigorous due
          diligence
        </h1>
        <p>
          and real use case improving different blockchain ecosystems network.
        </p>
        <h4>Projects related to DeFi, NFT, DAO.</h4>
      </motion.section>
      <section
        className="launchpad-section-container"
        style={{ margin: "50px auto" }}>
        {/* Arrow left */}
        <button onClick={handleChangeSectionClick} className="arrow-btn">
          <i className="mdi mdi-chevron-left"></i>
        </button>

        <div className="launchpad-list-container">
          {catapultDltData.map((item, index) => (
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1.3, ease: "easeInOut" }}
              className="launchpad-list-item-blue"
              key={index}>
              <div 
              style={{
                width: "100%",
                height: 170,
                filter: "drop-shadow(2px 4px 8px #585858)",
                borderTopLeftRadius: 18,
                borderTopRightRadius: 18,
              }}>

              </div>
              {/* <img
                //src={item.img}
                src=""
                alt=""
                style={{
                  width: "100%",
                  height: 170,
                  filter: "drop-shadow(2px 4px 8px #585858)",
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
              /> */}
              {/* <h4 style={{ margin: "8px 8px 0 8px", color: "black" }}>
                {item.name}
              </h4>
              <p style={{ margin: "0 8px 8px 8px" }}>{item.description}</p>
              <motion.p
                whileHover={{
                  scale: 1.1,
                  transition: { duration: 0.2 },
                }}
                whileTap={{ scale: 0.9 }}
                style={{
                  backgroundColor: "#0058ff",
                  color: "white",
                  padding: 6,
                  borderRadius: 12,
                  fontWeight: "bold",
                  width: "30%",
                  textAlign: "center",
                  margin: "auto auto 6px",
                  cursor: "pointer",
                }}>
                Apply
              </motion.p> */}
            </motion.div>
          ))}
        </div>

        {/* Arrow right */}
        <button onClick={handleChangeSectionClick} className="arrow-btn">
          <i className="mdi mdi-chevron-right"></i>
        </button>
      </section>
    </div>
  );
};
export default CatapultDltProjects;
