import { useState, useEffect } from "react";
import NavItem from "./Navitem";
import { Routes } from "../../Routes";

const Navbar = () => {
  const [device, setDevice] = useState("");

  const [isMenuHide, setIsMenuHide] = useState(true);

  const [isContactWalletHide, setIsContactWalletHide] = useState(true);

  const toggleVisibility = (cb) => {
    return () => {
      cb((prev) => !prev);
    };
  };

  useEffect(() => {
    setDevice(window.innerWidth < 1024 ? "phone" : "desktop");
  }, []);

  return (
    <nav className="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-transparent bg-transparent justify-content-between">
      <a href="/home">
        <img
          src="/images/logo-white.png"
          style={styleBox[device]}
          className="logo"
          alt="logo"
        />
      </a>

      <button
        onClick={toggleVisibility(setIsMenuHide)}
        className="navbar-toggler"
        type="button"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        {isMenuHide ? (
          <i className="mdi mdi-menu"></i>
        ) : (
          <i className="mdi mdi-close"></i>
        )}
      </button>

      <div className={`menu-wrapper${!isMenuHide ? " is-open" : ""}`}>
        <ul className="navbar-nav navbar-center m-0" id="mySidenav">
          {Routes.map((route) => {
            if (route.title === "Catapult") {
              return (
                <li className={"catapult-menu-btn"} key={route.title}>
                  <span className="nav-link text-uppercase">
                    {route.title} <i className="mdi mdi-menu-down"></i>
                  </span>

                  <ul className="catapult-sub-menu">
                    <li>
                      <a
                        href="/catapult-eco-sto"
                        className="catapult-sub-menu-item"
                      >
                        Eco-STO's
                      </a>
                    </li>
                    <li>
                      <a
                        href="/catapult-dlt-projects"
                        className="catapult-sub-menu-item"
                      >
                        DLT projects
                      </a>
                    </li>
                  </ul>
                </li>
              );
            }

            return (
              <NavItem title={route.title} url={route.url} key={route.title} />
            );
          })}
        </ul>

        <ul className="nav actions-buttons navbar-nav navbar-right">
          <li className="connect-wallet ff-monte">
            <button
              onClick={toggleVisibility(setIsContactWalletHide)}
              type="button"
              className="btn btn-sm btn-custom btn-custom-active btn-shadow navbar-btn  waves-effect waves-light"
            >
              Connect Wallet
            </button>

            <ul
              className={`connect-wallet-nav ${
                !isContactWalletHide ? " is-open" : ""
              }`}
            >
              <li>
                <button>
                  Solana <i className="mdi mdi-menu-down"></i>
                </button>
              </li>
              <li>
                <button>
                  NEAR <i className="mdi mdi-menu-down"></i>
                </button>
              </li>
              <li>
                <button>
                  Velas <i className="mdi mdi-menu-down"></i>
                </button>
              </li>
              <li>
                <button>
                  Harmony <i className="mdi mdi-menu-down"></i>
                </button>
              </li>
              <li>
                <button>
                  Ethereum <i className="mdi mdi-menu-down"></i>{" "}
                </button>
              </li>
              <li>
                <button>
                  Everscale <i className="mdi mdi-menu-down"></i>
                </button>
              </li>
              <li>
                <button>
                  Algorand <i className="mdi mdi-menu-down"></i>
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
};

const styleBox = {
  phone: {
    height: 40,
    width: 150,
  },
  desktop: {
    height: 40,
    width: 150,
  },
};

export default Navbar;
