import React from "react";

import "./style.css";
import { recentTradesActiveOrders } from "../../../../assets/data";

export default function RecentTrade() {
  return (
    <div
      style={{
        backgroundColor: "#050d22",
        borderRadius: 8,
        flex: 1,
        color: "#b9b9b9",
        padding: 8,
      }}
    >
      <p style={{ textAlign: "center" }}>My Recent Trades {"&"} Active Orders</p>
      <p
        style={{
          display: "flex",
          justifyContent: "space-around",
          borderBottom: "2px solid #0058ff",
          textAlign: "center",
        }}
      >
        <div style={{ flex: 2 }}>Rate</div>
        <div style={{ flex: 2 }}>Amount</div>
        <div style={{ flex: 3 }}>Time</div>
      </p>

      <div className="recentTradeActiveOrdersWrapper" style={{ overflowY: "scroll", height: 280 }}>
        <table className="recentTradeActiveOrders">
          <tbody>
            {recentTradesActiveOrders.map((item) => (
              <React.Fragment>
                <tr className="recent_trade_list" style={{ backgroundColor: "#0c152d" }}>
                  <td>{item.rate}</td>
                  <td>{item.amount}</td>
                  <td>{item.time}</td>
                </tr>
                <tr style={{ backgroundColor: "transparent" }}></tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
