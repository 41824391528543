import React from "react";
import Zoom from "react-reveal/Reveal";

class Minter extends React.Component {
  componentDidMount() {}

  state = {
    walletConnected: false,
    fromValue: "0.0",
    toValue: "0.0",
    manageTolerance: false,
    slippageTolerance: "1",
  };

  render() {
    return (
      <section className="section Minter m-0 p-0" id="Minter">
        <div className="black-layer-2">
          <div className="container mt-2 mb-3 ptb-12">
            <div className="row align-items-center justify-content-center mt-4">
              <h3 className="text-white m-4">Coming very soon!</h3>
              <img
                alt=""
                src="images/metaverse.jpeg"
                className="img-fluid "
                width="100%"
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Minter;
