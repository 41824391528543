import React, { Component } from "react";
import "./style.css";

import LeftNav from "./LeftNav/LeftNav";

export default class Novobook extends Component {
  render() {
    return (
      <div className="Beginner_Container">
        <LeftNav />

        <div
          style={{
            padding: 3,
            width: "100%",
            display: "flex",
          }}
        >
          <section style={{ padding: 3, width: "45%", margin: "auto" }}>
            <div>
              <h1
                style={{
                  color: "white",
                  borderLeft: "2px solid #0058ff",
                  paddingLeft: 20,
                  fontSize: 25,
                  fontWeight: "bold",
                }}
              >
                WELCOME TO NOVOBOOK
              </h1>
              <div style={{ margin: 0 }}>
                <p
                  style={{
                    color: "#8e8e8f",
                    fontSize: 13,
                    padding: 0,
                    margin: 0,
                  }}
                >
                  Easy to use tools to protect your money and make good trades
                </p>

                <p
                  style={{
                    color: "white",
                    fontSize: 25,
                    padding: 0,
                    margin: 0,
                  }}
                >
                  First Buyers can only buy Stable Coins
                </p>
              </div>
            </div>

            <div style={{ padding: 3 }}>
              <div className="assets_container">
                <p className="asset_item">PAXG</p>
                <p className="asset_item">SILVER</p>
                <p className="asset_item">TUSD</p>
                <p className="asset_item">NOK</p>

                <p className="asset_item">TCAD</p>
                <p className="asset_item">TAUD</p>
                <p className="asset_item">CNY</p>
                <p className="asset_item">PLN</p>

                <p className="asset_item">EURO</p>
                <p className="asset_item">CHF</p>
                <p className="asset_item">NSD</p>
                <p className="asset_item">HRK</p>

                <p className="asset_item">TGBP</p>
                <p className="asset_item">DKK</p>
                <p className="asset_item">ILS</p>
                <p className="asset_item">RON</p>

                <p className="asset_item">SGD</p>
                <p className="asset_item">THKD</p>
                <p className="asset_item">MXP</p>
                <p className="asset_item">RUN</p>

                <p className="asset_item">AED</p>
                <p className="asset_item">JPY</p>
                <p className="asset_item">NOK</p>
                <p className="asset_item">SAR</p>
              </div>

              <p style={{ marginTop: 10, marginBottom: 9, fontSize: 15 }} className="exchange_btn blue_btn">
                Watch Tutorials to access other Digital Assets
              </p>

              <div
                style={{
                  backgroundColor: "#050d22",
                  color: "white",
                  fontSize: 15,
                  padding: 7,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ padding: 0, margin: 0 }}>JOIN BANCAMBIOS ACADEMY</p>

                <p style={{ padding: 0, margin: 0 }}>Interactive Community Earning while Learning</p>
              </div>

              <p
                style={{
                  color: "white",
                  textAlign: "center",
                  margin: 5,
                  fontSize: 15,
                }}
              >
                Calculate/ Know your Fees: Visit Bancambios Academy to earn free trades
              </p>

              <div className="ast_detail_container">
                <div className="ast_item_detail" style={{ border: "1px solid #2abb00" }}>
                  <div className="ast_i">
                    <img src={require("./icons/usd.svg").default} style={{ width: 30 }} alt="icon" />
                    <p
                      style={{
                        fontSize: 14,
                        margin: "auto auto auto 8px",
                      }}
                    >
                      <span style={{ fontSize: 11, color: "#2abb00" }}>Buying</span>
                      <br />
                      TUSD
                    </p>
                  </div>
                  <div className="ast_i" style={{ flexDirection: "column" }}>
                    <p style={{ fontSize: 18, margin: "auto 0" }}>$100</p>
                    <p style={{ color: "pink", fontSize: 13, margin: "auto 0" }}>Taker 0.01%</p>
                  </div>
                </div>

                <div className="ast_item_detail" style={{ border: "1px solid #ff0058" }}>
                  <div className="ast_i">
                    <img src={require("./icons/baft.svg").default} style={{ width: 30 }} alt="icon" />
                    <p
                      style={{
                        fontSize: 14,
                        margin: "auto auto auto 8px",
                      }}
                    >
                      <span style={{ fontSize: 11, color: "#ff0058" }}>Selling</span>
                      <br />
                      BX
                    </p>
                  </div>
                  <div className="ast_i" style={{ flexDirection: "column" }}>
                    <p style={{ fontSize: 18, margin: "auto 0" }}>1,038.42</p>
                    <p
                      style={{
                        color: "pink",
                        fontSize: 13,
                        margin: "auto 0",
                      }}
                    >
                      Maker 0.01%
                    </p>
                  </div>
                </div>

                <div className="ast_item_detail" style={{ alignItems: "center" }}>
                  <p style={{ padding: 0, margin: 0 }}>Calculate your interest rate</p>
                  <p
                    style={{
                      color: "pink",
                      fontSize: 13,
                      margin: "auto 0",
                    }}
                  >
                    Actual rate: <span style={{ color: "#ff0058" }}>8.88%</span>
                  </p>
                  <p
                    style={{
                      color: "pink",
                      fontSize: 13,
                      margin: "auto 0",
                    }}
                  >
                    1,000 TUSC 10,000 TUSC <span style={{ color: "#0058ff" }}>Custom</span>
                  </p>
                  <span style={{ fontSize: 10, color: "#ff0058" }}>$4.7 per month $47 per month</span>
                </div>
              </div>
            </div>

            <p
              style={{
                color: "#8e8e8f",
                margin: "0 23px",
                fontSize: 14,
                padding: 0,
                textAlign: "center",
              }}
            >
              If you want access to other fluctuating currencies, you must complete the Bancambios Academy through
              Bancambios Tutorials.
            </p>
          </section>

          <section
            style={{
              padding: 3,
              width: "40%",
              margin: "0px auto auto auto",
              height: "100%",
              // backgroundColor: "red",
            }}
          >
            <p style={{ color: "white", textAlign: "center" }}>Convet your Legacy Currency into a Stable Coin</p>
            <div>
              <div className="exchange_inp_btn_container">
                <input className="exchange_input" value="0.0" type="number" name="amount" />
                <p className="exchange_dropdown">Domestic FIAT</p>
              </div>

              <div className="exchange_inp_btn_container">
                <input className="exchange_input" value="0.0" type="number" name="amount" />
                <p className="exchange_dropdown">Domestic FIAT</p>
              </div>
              <p className="exchange_btn grey_btn">AVAILABLE BALANCE</p>
            </div>

            <div className="history_container">
              <p className="exchange_btn blue_btn">SET PARAMETERS: STOP-LOSS, TAKE-LOSS, ACCUMULATE</p>
              <p
                style={{
                  padding: 0,
                  margin: "8px 0",
                  textAlign: "center",
                  color: "white",
                }}
              >
                CHOOSE PAIRING
              </p>

              <div
                style={{
                  display: "flex",
                  color: "white",
                  height: 27,
                  justifyContent: "space-between",
                  width: "96%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <p
                  style={{
                    margin: 0,
                    border: "1px solid #0058ff",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  TUSD
                  <span style={{ backgroundColor: "#0058ff", padding: "0 6px" }}>BX</span>
                </p>

                <p
                  style={{
                    margin: 0,
                    border: "1px solid #0058ff",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  USDT
                  <span style={{ backgroundColor: "#0058ff", padding: "0 6px" }}>BTC</span>
                </p>

                <p
                  style={{
                    margin: 0,
                    border: "1px solid #0058ff",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  XRP
                  <span style={{ backgroundColor: "#0058ff", padding: "0 6px" }}>ETH</span>
                </p>

                <p
                  style={{
                    margin: 0,
                    border: "1px solid #0058ff",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  XRP
                  <span style={{ backgroundColor: "#0058ff", padding: "0 6px" }}>BTC</span>
                </p>

                <p
                  style={{
                    margin: 0,
                    border: "1px solid #0058ff",
                    padding: 0,
                    display: "flex",
                  }}
                >
                  XRP
                  <span style={{ backgroundColor: "#0058ff", padding: "0 6px" }}>DOT</span>
                </p>
              </div>

              <div
                style={{
                  width: "96%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  backgroundColor: "#030711",
                  height: 98,
                  marginTop: 6,
                  marginBottom: 6,
                  borderRadius: 6,
                  padding: 4,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "#0058ff",
                    fontSize: 14,
                  }}
                >
                  <p>Stop-loose tool</p>
                  <p>Custom</p>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "white",
                    alignItems: "center",
                    marginTop: 4,
                    marginBottom: 4,
                  }}
                >
                  <p style={{ fontSize: 13, color: "grey" }}>Buy With Auto-Sell if it goes down by</p>

                  <div style={{ display: "flex", fontSize: 13 }}>
                    <p
                      style={{
                        backgroundColor: "#ff0058",
                        borderRadius: 4,
                        padding: 2,
                        width: 40,
                        marginRight: 3,
                      }}
                    >
                      -5%
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ff0058",
                        borderRadius: 4,
                        padding: 2,
                        width: 40,
                        marginRight: 3,
                      }}
                    >
                      -10%
                    </p>
                    <p
                      style={{
                        borderRadius: 4,
                        padding: 2,
                        width: 40,
                        border: "1px solid #ff0058",
                      }}
                    >
                      - %
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    color: "white",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p>WALLET BALANCE</p>

                  <div
                    style={{
                      backgroundColor: "#050d22",
                      borderRadius: 20,
                      flex: 1,
                      marginLeft: 10,
                      height: 10,
                    }}
                  ></div>
                </div>
              </div>

              <div
                style={{
                  width: "96%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  backgroundColor: "#030711",
                  height: 98,
                  marginTop: 6,
                  marginBottom: 6,
                  borderRadius: 6,
                  padding: 4,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "#0058ff",
                    fontSize: 14,
                  }}
                >
                  <p>Stop-loose tool</p>
                  <p>Custom</p>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "white",
                    marginTop: 4,
                    marginBottom: 4,
                    alignItems: "center",
                  }}
                >
                  <p style={{ fontSize: 13, color: "grey" }}>Buy With Auto-Sell if it goes down by</p>

                  <div style={{ display: "flex", fontSize: 13 }}>
                    <p
                      style={{
                        backgroundColor: "#2abb00",
                        borderRadius: 4,
                        padding: 2,
                        width: 40,
                        marginRight: 3,
                      }}
                    >
                      +10%
                    </p>
                    <p
                      style={{
                        backgroundColor: "#2abb00",
                        borderRadius: 4,
                        padding: 2,
                        width: 40,
                        marginRight: 3,
                      }}
                    >
                      +15%
                    </p>
                    <p
                      style={{
                        borderRadius: 4,
                        padding: 2,
                        width: 40,
                        border: "1px solid #2abb00",
                      }}
                    >
                      + %
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    color: "white",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p>WALLET BALANCE</p>

                  <div
                    style={{
                      backgroundColor: "#050d22",
                      borderRadius: 20,
                      flex: 1,
                      marginLeft: 10,
                      height: 10,
                    }}
                  ></div>
                </div>
              </div>

              <div
                style={{
                  width: "96%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  backgroundColor: "#030711",
                  height: 98,
                  marginTop: 6,
                  marginBottom: 6,
                  borderRadius: 6,
                  padding: 4,
                }}
              >
                <p
                  style={{
                    justifyContent: "space-between",
                    color: "#0058ff",
                    fontSize: 14,
                    marginLeft: "auto",
                    textAlign: "right",
                  }}
                >
                  Custom
                </p>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "white",
                    marginTop: 4,
                    marginBottom: 4,
                    alignItems: "center",
                  }}
                >
                  <p style={{ fontSize: 13, color: "grey" }}>Automatically buy more (if price goes down)</p>

                  <div style={{ display: "flex", fontSize: 13 }}>
                    <p
                      style={{
                        backgroundColor: "#ff0058",
                        borderRadius: 4,
                        padding: 2,
                        width: 40,
                        marginRight: 3,
                      }}
                    >
                      -5%
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ff0058",
                        borderRadius: 4,
                        padding: 2,
                        width: 40,
                        marginRight: 3,
                      }}
                    >
                      -10%
                    </p>
                    <p
                      style={{
                        borderRadius: 4,
                        padding: 2,
                        width: 40,
                        border: "1px solid #ff0058",
                      }}
                    >
                      - %
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    color: "white",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p>WALLET BALANCE</p>

                  <div
                    style={{
                      backgroundColor: "#050d22",
                      borderRadius: 20,
                      flex: 1,
                      marginLeft: 10,
                      height: 10,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
