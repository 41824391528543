import React, { Fragment } from "react";
import Converter from './Components/Converter'

const Stableswap = () => {
  return (
    <Fragment>
      <Converter></Converter> 
    </Fragment>
  );
};

export default Stableswap;