import Zoom from "react-reveal/Reveal";
import React from "react";
import halborn from "../../../assets/img/Partners/halborn_logo.svg";
import itsa from "../../../assets/img/Partners/itsa-logo-alpha.png";
import FireLogo from "../../../assets/img/Partners/FireLogoSmall.png";
// import quantstamp from '../../../assets/img/Partners/quantstamp_logo_horizontal.png';
// import pixelplex from '../../../assets/img/Partners/PixelPlex.png';
import gdfLogo from "../../../assets/img/Partners/gdfLogo.png";
// import kairon from '../../../assets/img/Partners/KaironLabsHorizontal.png';

//
import oceanCleanUp from "../../../assets/img/Protectors/Ocean-Cleanup-logo.png";
import seaSheperd from "../../../assets/img/Partners/seaSheperd.png";
import reefLife from "../../../assets/img/Partners/reefLife.png";
import missionBlue from "../../../assets/img/Partners/missionBlue.png";
//
import kudelski from "../../../assets/img/Partners/kudelski.png";
import zokyo from "../../../assets/img/Partners/zokyo.png";
import hacken from "../../../assets/img/Partners/Hackenlogo.jpg";

// ON THE MEDIA
class Partners extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <section className="section" id="partners">
        <div className="black-layer">
          <div className="section-content bg-darker">
            <div className="container">
              <Zoom bottom>
                <div className="row main-row">
                  <div className="col-md-12 text-center">
                    <Zoom bottom>
                      <h1 className="ff-monte main-title-3">Associations</h1>
                    </Zoom>
                    <br />
                  </div>
                  <div className="mt-4 row">
                    <div className="col-md-2"></div>
                    {/* <div className="col-md-3 col-sm-6 img-partner">
                      <a
                        href="https://halborn.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img alt="" src={FireLogo} />
                      </a>
                    </div> */}
                    {/* <div className="col-md-3 col-sm-6 img-partner">
											<a href="https://www.chainalysis.com/" target="_blank" rel="noreferrer">
												<img alt="" src={BlockchainAssociation} />
											</a>
										</div> */}

                    <div className="col-md-4 col-sm-6 img-partner">
                      <a
                        href="https://itsa.global/bancambios-became-a-member-of-the-international-token-standardization-association-itsa-e-v/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img alt="" src={gdfLogo} />
                      </a>
                    </div>

                    <div className="col-md-3 col-sm-6 img-partner">
                      <a
                        href="https://itsa.global/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img alt="" src={itsa} />
                      </a>
                    </div>
                    <div className="col-md-1"></div>
                  </div>
                </div>
              </Zoom>
            </div>
          </div>
          <div className="section-content bg-darker">
            <div className="container">
              <Zoom bottom>
                <div className="row main-row">
                  <div className="col-md-12 text-center">
                    <Zoom bottom>
                      <h1 className="ff-monte main-title-3">
                        Selected Impact Partners
                      </h1>
                    </Zoom>
                    <br />
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-3 col-sm-6 img-partner">
                      <a
                        href="https://www.seashepherdglobal.org/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img alt="" src={seaSheperd} />
                      </a>
                    </div>
                    <div className="col-md-3 col-sm-6 img-partner">
                      <a
                        href="https://theoceancleanup.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img alt="" src={oceanCleanUp} />
                      </a>
                    </div>
                    <div className="col-md-3 col-sm-6 img-partner">
                      <a
                        href="https://www.reeflifefoundation.org/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img alt="" src={reefLife} />
                      </a>
                    </div>
                    <div className="col-md-3 col-sm-6 img-partner">
                      <a
                        href=" https://mission-blue.org/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img alt="" src={missionBlue} />
                      </a>
                    </div>
                  </div>
                </div>
              </Zoom>
            </div>
          </div>
          {/* <div className="section-content bg-darker">
            <div className="container">
              <Zoom bottom>
                <div className="row main-row">
                  <div className="col-md-12 text-center">
                    <Zoom bottom>
                      <h1 className="ff-monte main-title-3">
                        Committed to 4 Cybersecurity Audits
                      </h1>
                    </Zoom>
                    <br />
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-3 col-sm-6 img-partner">
                      <a
                        href="https://hackenproof.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img alt="" src={hacken} />
                      </a>
                    </div>
                    <div className="col-md-3 col-sm-6  img-partner">
                      <a
                        href="https://halborn.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img alt="" src={halborn} />
                      </a>
                    </div>
                    <div className="col-md-3 col-sm-6  img-partner">
                      <a
                        href="https://www.zokyo.io "
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img alt="" src={zokyo} className="w-80" />
                      </a>
                    </div>
                    <div className="col-md-3 col-sm-6  img-partner">
                      <a
                        href="https://kudelskisecurity.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img alt="" src={kudelski} />
                      </a>
                    </div>
                    {/* <div className='col-md-3 col-sm-12 img-partner'>
											<a href='https://itsa.global/' target="_blank" rel="noreferrer">
												<img alt="" src={itsa} />
											</a>
										</div> */}
          {/* </div>
                </div>
              </Zoom>
            </div>
          </div> */}
          {/* <div className="section-content bg-darker">
            <div className="container">
              <Zoom bottom>
                <div className="row main-row">
                  <div className="col-md-12 text-center">
                    <Zoom bottom>
                      <h1 className="ff-monte main-title-3">On the Media</h1>
                    </Zoom>
                    <br />
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-3 col-sm-6 img-partner">
                      <a
                        href="https://www.chainalysis.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          alt=""
                          src="images/partners/media/thecapital.png"
                        />
                      </a>
                    </div>
                    <div className="col-md-2 col-sm-6 img-partner">
                      <a
                        href="https://www.benzinga.com/pressreleases/21/10/g23208619/bancambios-chooses-solana-blockchain-to-build-its-impact-driven-defi-platform"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img alt="" src="images/partners/media/benzinga.png" />
                      </a>
                    </div>
                    <div className="col-md-2 col-sm-6 img-partner">
                      <a
                        href="https://finance.yahoo.com/news/bancambios-chooses-solana-blockchain-build-070000535.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img alt="" src="images/partners/media/yahoo.png" />
                      </a>
                    </div>

                    <div className="col-md-2 col-sm-6 img-partner">
                      <a
                        href="https://www.marketwatch.com/press-release/bancambios-chooses-solana-blockchain-to-build-its-impact-driven-defi-platform-2021-10-03"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          alt=""
                          src="images/partners/media/marketwatch.png"
                        />
                      </a>
                    </div>
                    <div className="col-md-3 col-sm-12 img-partner">
                      <a
                        href="https://www.marketwatch.com/press-release/bancambios-chooses-solana-blockchain-to-build-its-impact-driven-defi-platform-2021-10-03"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          alt=""
                          src="images/partners/media/seekingalpha.png"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </Zoom>
            </div>
          </div> */}
        </div>
      </section>
    );
  }
}

export default Partners;
