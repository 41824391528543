import React from "react";
import Bounce from "react-reveal/Reveal";
import Slider from "react-slick";
class Team extends React.Component {
  render() {
    const settings = {
      className: "center",
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 5,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <section className="section" id="team">
        {/* <div className="col-lg-12 p-0">
            <img className="img-divider" alt="" src="images/char2.png" width="100%" />
        </div> */}
        <Bounce bottom cascade>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <h1 className="text-center text-light ff-monte main-title-3">
                  Our Team
                </h1>
                <div className="section-title-border margin-t-20"></div>
              </div>
            </div>
            <div className="row mt-5 p-2">
              <div className="col-12">
                <Slider {...settings}>
                  <div className="pt-5">
                    <div className="advisor-card-teams-section text-center team-card mr-3">
                      <label className="text-white mt-4">Oscar</label>
                      <span className="text-white">Project Builder</span>
                      <div className="team-social text-white">
                        <a
                          className="socialMediaIcon"
                          href="https://twitter.com/olahworld"
                          target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        <a
                          className="socialMediaIcon"
                          href="https://www.linkedin.com/in/olahventures"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* <div className="pt-5">
                    <div className="advisor-card bg-dark text-center team-card mr-3">
                      <img
                        alt=""
                        src="images/team/evgen.jpeg"
                        className="img-fluid rounded  "
                        width="100%"
                      />
                      <label className="text-white mt-4">Evgen Verzun</label>
                      <span className="text-white">DeFi Engineer</span>
                      <div className="team-social text-white">
                        <a
                          className="socialMediaIcon"
                          // href="https://twitter.com/olahworld"
                          // target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        <a
                          className="socialMediaIcon"
                          // href="https://www.linkedin.com/in/olahventures"
                          // target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div> */}

                  <div className="pt-5">
                    <div className="advisor-card-teams-section text-center team-card mr-3">
                      {/* <img
                        alt=""
                        src="images/team/ralf.jpeg"
                        className="img-fluid rounded  "
                        width="100%"
                      /> */}
                      <label className="text-white mt-4"> Rafic</label>
                      <span className="text-white">
                        Senior Rust Lead Developer
                      </span>
                      <div className="team-social text-white">
                        <a
                        // href="https://www.linkedin.com/in/irina-valtfogel-56a559223"
                        // target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        <a
                          href="https://www.linkedin.com/in/deveus/"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="pt-5">
                    <div className="advisor-card-teams-section text-center team-card mr-3">
                      <label className="text-white mt-4">Zainab Shakeel</label>
                      <span className="text-white">Sr. Software Developer</span>
                      <div className="team-social text-white">
                        {/* <a
                          className="socialMediaIcon"
                          href="https://twitter.com/olahworld"
                          target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a> */}
                        <a
                          className="socialMediaIcon"
                          href="https://www.linkedin.com/in/zainab-shakeel-b3556b149/"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="pt-5">
                    <div className="advisor-card-teams-section text-center team-card mr-3">
                      {/* <img
                        alt=""
                        src="images/team/oleg.jpeg"
                        className="img-fluid rounded "
                        width="100%"
                      /> */}
                      <label className="text-white mt-4">Oleg Miniuk</label>
                      <span className="text-white">Solution Architect</span>
                      <div className="team-social text-white">
                        <a
                        // href="https://www.linkedin.com/in/irina-valtfogel-56a559223"
                        // target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        <a
                          href="https://www.linkedin.com/in/oleg-miniuk"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="pt-5">
                    <div className="advisor-card-teams-section text-center team-card mr-3">
                      {/* <img
                        alt=""
                        src="images/team/newavatar/Rectangle603.png"
                        className="img-fluid rounded "
                        width="100%"
                      /> */}
                      <label className="text-white mt-4">Luciano</label>
                      <span className="text-white">COO</span>
                      <div className="team-social text-white">
                        <a
                          href="https://www.linkedin.com/in/irina-valtfogel-56a559223"
                          target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        <a
                          href="https://www.linkedin.com/in/lucianorod"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="pt-5">
                    <div className="advisor-card-teams-section text-center team-card mr-3">
                      {/* <img
                        alt=""
                        src="images/team/artem.jpeg"
                        className="img-fluid rounded "
                        width="100%"
                      /> */}
                      <label className="text-white mt-4">Artem</label>
                      <span className="text-white">Blockchain Logic</span>
                      <div className="team-social text-white">
                        <a
                        // href="https://www.linkedin.com/in/irina-valtfogel-56a559223"
                        // target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        <a
                          href="https://www.linkedin.com/in/artsemiy-zhylnikau-866286158/"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="pt-5">
                    <div className="advisor-card-teams-section text-center team-card mr-3">
                      {/* <img
                        alt=""
                        src="images/team/oleksa.jpeg"
                        className="img-fluid rounded "
                        width="100%"
                      /> */}
                      <label className="text-white mt-4">Oleksandr</label>
                      <span className="text-white">
                        Rust Programs Developer
                      </span>
                      <div className="team-social text-white">
                        <a
                        // href="https://www.linkedin.com/in/irina-valtfogel-56a559223"
                        // target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        <a
                          href="https://www.linkedin.com/in/oleksandr-mykhailenko-652b9a1a9/"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="pt-5">
                    <div className="advisor-card-teams-section text-center team-card mr-3">
                      {/* <img
                        alt=""
                        src="images/team/avatar/andrew-avatar.png"
                        className="img-fluid rounded "
                        width="100%"
                      /> */}
                      <label className="text-white mt-4">Andrew</label>
                      <span className="text-white">
                        Rust Blockchain Developer
                      </span>
                      <div className="team-social text-white">
                        <a
                        // href="https://www.linkedin.com/in/irina-valtfogel-56a559223"
                        // target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        <a
                        // href="https://www.linkedin.com/in/irina-valtfogel-56a559223"
                        // target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="pt-5">
                    <div className="advisor-card-teams-section text-center team-card mr-3">
                      {/* <img
                        alt=""
                        src="images/team/vlad.jpeg"
                        className="img-fluid rounded "
                        width="100%"
                      /> */}
                      <label className="text-white mt-4">viacheslav</label>
                      <span className="text-white">Full Stack Developer</span>
                      <div className="team-social text-white">
                        <a
                        // href="https://www.linkedin.com/in/irina-valtfogel-56a559223"
                        // target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        <a
                          href="https://www.linkedin.com/in/viacheslav-vozniuk-43a98190/"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="pt-5">
                    <div className="advisor-card-teams-section text-center team-card mr-3">
                      {/* <img
                        alt=""
                        src="images/team/debys.jpeg"
                        className="img-fluid rounded "
                        width="100%"
                      /> */}
                      <label className="text-white mt-4">Denys</label>
                      <span className="text-white">Project Manager</span>
                      <div className="team-social text-white">
                        <a
                        // href="https://www.linkedin.com/in/irina-valtfogel-56a559223"
                        // target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        <a
                          href="https://www.linkedin.com/in/denys-hrytchuk"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="pt-5">
                    <div className="advisor-card-teams-section  text-center team-card mr-3">
                      {/* <img
                        alt=""
                        src="images/team/ivan.jpeg"
                        className="img-fluid rounded "
                        width="100%"
                      /> */}
                      <label className="text-white mt-4">Ivan</label>
                      <span className="text-white">Frontend Developer</span>
                      <div className="team-social text-white">
                        <a
                        // href="https://www.linkedin.com/in/irina-valtfogel-56a559223"
                        // target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        <a
                          href="https://www.linkedin.com/in/ivan-kolomiyets-9b5018a5/"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="pt-5">
                    <div className="advisor-card-teams-section text-center team-card mr-3">
                      {/* <img
                        alt=""
                        src="images/team/olena.jpeg"
                        className="img-fluid rounded "
                        width="100%"
                      /> */}
                      <label className="text-white mt-4">Isabella Händel</label>
                      <span className="text-white">
                        Marketing & Social Media
                      </span>
                      <div className="team-social text-white">
                        <a
                        // href="https://www.linkedin.com/in/irina-valtfogel-56a559223"
                        // target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        <a
                          href="https://www.linkedin.com/in/isabellahandel/"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="pt-5">
                    <div className="advisor-card-teams-section text-center team-card mr-3">
                      {/* <img
                        alt=""
                        src="images/team/valery.jpeg"
                        className="img-fluid rounded "
                        width="100%"
                      /> */}
                      <label className="text-white mt-4">Valeriy</label>
                      <span className="text-white">Blockchain Engineer</span>
                      <div className="team-social text-white">
                        <a
                        // href="https://www.linkedin.com/in/irina-valtfogel-56a559223"
                        // target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        <a
                        // href="https://www.linkedin.com/in/irina-valtfogel-56a559223"
                        // target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="pt-5">
                    <div className="advisor-card-teams-section text-center team-card mr-3">
                      {/* <img
                        alt=""
                        src="images/team/vadym.jpeg"
                        className="img-fluid rounded "
                        width="100%"
                      /> */}
                      <label className="text-white mt-4">vadym</label>
                      <span className="text-white">Sr. React JS Developer</span>
                      <div className="team-social text-white">
                        <a
                        // href="https://www.linkedin.com/in/irina-valtfogel-56a559223"
                        // target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        <a
                          href="https://www.linkedin.com/in/vadym-vitenko-600824164"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="pt-5">
                    <div className="advisor-card-teams-section text-center team-card mr-3">
                      {/* <img
                        alt=""
                        src="images/team/avatar/sergey-avatar.png"
                        className="img-fluid rounded "
                        width="100%"
                      /> */}
                      <label className="text-white mt-4">Sergey</label>
                      <span className="text-white">Senior QA Manual</span>
                      <div className="team-social text-white">
                        <a
                        // href="https://www.linkedin.com/in/irina-valtfogel-56a559223"
                        // target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        <a
                        // href="https://www.linkedin.com/in/irina-valtfogel-56a559223"
                        // target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <div className="pt-5">
                    <div className='text-center team-card'>
                      <img alt="" src="images/team/Artsemiy.jpeg" className="img-fluid rounded " width="100%" />
                      <label className="text-white mt-4"> Artsemiy
                      </label>
                      <div className="team-social text-white">
                        <a className="socialMediaIcon" href="https://www.linkedin.com/in/artsemiy-zhylnikau-866286158/" target="blank">
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        <a className="socialMediaIcon" href="https://www.linkedin.com/in/artsemiy-zhylnikau-866286158/" target="blank">
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="pt-5">
                    <div className='text-center team-card'>
                      <img alt="" src="images/team/AndreyTikov.jpeg" className="img-fluid rounded " width="100%" />
                      <label className="text-white mt-4"> Andrey
                      </label>
                      <div className="team-social text-white">
                        <a className="socialMediaIcon" href="" target="blank">
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        <a className="socialMediaIcon" href="" target="blank">
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="pt-5">
                    <div className="advisor-card bg-dark text-center team-card mr-3">
                      <img
                        alt=""
                        src="images/team/CarlosGraterol.jpg"
                        className="img-fluid rounded "
                        width="100%"
                      />
                      <label className="text-white mt-4"> Carlos </label>
                      <span className="text-white">Graphic Designer</span>
                      <div className="team-social text-white">
                        <a
                          className="socialMediaIcon"
                          href="https://www.linkedin.com/in/daniel-warrick-08b22b169"
                          target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        <a
                          className="socialMediaIcon"
                          href="https://www.linkedin.com/in/mirza-usman"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="pt-5">
                    <div className="advisor-card bg-dark text-center team-card mr-3">
                      <img
                        alt=""
                        src="images/team/avatar/luciano-avatar.jpg"
                        className="img-fluid rounded "
                        width="100%"
                      />
                      <label className="text-white mt-4"> Luciano </label>
                      <span className="text-white">Operations Manager</span>
                      <div className="team-social text-white">
                        <a
                          className="socialMediaIcon"
                          href="https://www.linkedin.com/in/lucianorod/"
                          target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        <a
                          className="socialMediaIcon"
                          href="https://www.linkedin.com/in/lucianorod/"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="pt-5">
                    <div className='text-center team-card'>
                      <img alt="" src="images/team/Zoe Fleischer.jpg" className="img-fluid rounded " width="100%" />
                      <label className="text-white mt-4"> Zoe
                      </label>
                      <div className="team-social text-white">
                        <a className="socialMediaIcon" href="https://www.linkedin.com/in/zoe-fleischer" target="blank">
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        <a className="socialMediaIcon" href="https://www.linkedin.com/in/zoe-fleischer" target="blank">
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div> */}
                  <div className="pt-5">
                    <div className="advisor-card-teams-section text-center team-card mr-3">
                      {/* <img
                        alt=""
                        src="images/team/AnnaStarikovaRecortada.jpeg"
                        className="img-fluid rounded "
                        width="100%"
                      /> */}
                      <label className="text-white mt-4"> Anna</label>
                      <span className="text-white">UI/UX Designer</span>
                      <div className="team-social text-white">
                        <a
                          href="https://www.linkedin.com/in/anna-starikova-6b5426186/"
                          target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        {/* <a
                          href="https://www.linkedin.com/in/anna-starikova-6b5426186/"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a> */}
                      </div>
                    </div>
                  </div>
                  <div className="pt-5">
                    <div className="advisor-card-teams-section  text-center team-card mr-3">
                      {/* <img
                        alt=""
                        src="images/team/CarlLangdon.jpeg"
                        className="img-fluid rounded "
                        width="100%"
                      /> */}
                      <label className="text-white mt-4"> Carl</label>
                      <span className="text-white">Community Manager</span>
                      <div className="team-social text-white">
                        <a
                          href="https://www.linkedin.com/in/carlmlangdon/"
                          target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        <a
                          href="https://www.linkedin.com/in/carlosgmssimoes/"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <div className="pt-5">
                    <div className="advisor-card bg-dark text-center team-card mr-3">
                      <img
                        alt=""
                        src="images/team/avatar/Victoria.jpg"
                        className="img-fluid rounded "
                        width="100%"
                      />
                      <label className="text-white mt-4">Victoria</label>
                      <span className="text-white">Social Media & PR</span>
                      <div className="team-social text-white">
                        <a
                          href="https://www.linkedin.com/in/carlmlangdon/"
                          target="blank"
                        >
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        <a
                          href="https://www.linkedin.com/in/carlmlangdon/"
                          target="blank"
                        >
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="pt-5">
                    <div className='text-center team-card'>
                      <img alt="" src="images/advisors/Mirza Usman.jpg" className="img-fluid rounded " width="100%" />
                      <label className="text-white mt-4"> Mirza
                      </label>
                      <div className="team-social text-white">
                        <a className="socialMediaIcon" href="https://www.linkedin.com/in/mirza-usman" target="blank">
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        <a className="socialMediaIcon" href="https://www.linkedin.com/in/mirza-usman" target="blank">
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="pt-5">
                    <div className='text-center team-card'>
                      <img alt="" src="images/team/rustDeveloper.jpeg" className="img-fluid rounded " width="100%" />
                      <label className="text-white mt-4"> Mateo
                      </label>
                      <div className="team-social text-white">
                        <a href="" target="blank">
                          <i className="fab fa-twitter text-blue"></i>
                        </a>
                        <a href="" target="blank">
                          <i className="fab fa-linkedin text-blue"></i>
                        </a>
                      </div>
                    </div>
                  </div> */}
                </Slider>
              </div>
            </div>
          </div>
        </Bounce>
      </section>
    );
  }
}

export default Team;
