import { useState, useEffect } from "react";

const HorizontalToolBar = () => {
  const [device, setDevice] = useState("");
  useEffect(() => {
    setDevice(window.innerWidth < 992 ? "phone" : "screen");
  }, []);
  const horizontalTools = ["Line", "1m", "5m", "15m", "30m", "1h", "4h", "8h", "1D", "1W", "1M"];

  return device === "screen" ? (
    <div className="time_diff_container">
      {horizontalTools.map((tool) => (
        <p>{tool}</p>
      ))}
      <a href="/alphatrade">Trading View chart</a>
      <a href="/alphatrade">Market depth chart</a>
    </div>
  ) : (
    <div class="dropdown">
      <button
        class="btn btn-dark btn-sm data-toggle "
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i class="fa fa-ellipsis-v"></i>
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        {horizontalTools.map((tool) => (
          <a href="!#" className="dropdown-item">
            {tool}
          </a>
        ))}
        <a href="!#" className="dropdown-item">
          Trading View chart
        </a>
        <a href="!#" className="dropdown-item">
          Market depth chart
        </a>
      </div>
    </div>
  );
};
export default HorizontalToolBar;
