import React, { Component } from "react";
import "./style.css";

export default class Login extends Component {
  render() {
    return (
      <div className="login_container">
        <h1>Login</h1>
      </div>
    );
  }
}
