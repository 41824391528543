import "./style.css";
import AlphaLeftNav from "./components/AlphaLeftNav/AlphaLeftNav";
import AlphatradeRight from "./components/AlphatradeRight/AlphatradeRight";
import RecentTrade from "./components/RecentTrade/RecentTrade";
import AmountCal from "./components/AmountCal/AmountCal";
import VerticleToolBar from "./components/VerticleToolsBar";
import HorizontalToolBar from "./components/HorizontalToolBar";
import { data } from "../../assets/data.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const Alphatrade = () => {
  return (
    <div className="Alphatrade">
      <AlphaLeftNav />

      <section style={{ margin: "5px 7px 0 7px", flex: 1, flexDirection: "column" }}>
        <div className="graph_container">
          <VerticleToolBar />
          <section style={{ marginLeft: 3, flex: 1, textAlign: "right" }}>
            <HorizontalToolBar />
            <img
              src={require("../../assets/img/graph.png").default}
              style={{ width: "100%", marginTop: "auto" }}
              alt=""
            />
          </section>
        </div>

        <div style={{ display: "flex", marginTop: 7, color: "#b9b9b9" }}>
          <AmountCal />

          <RecentTrade />
        </div>

        <div
          style={{
            backgroundColor: "#050d22",
            borderRadius: 8,
            marginTop: 8,
            padding: 18,
          }}
        >
          <div style={{ display: "flex" }}>
            <p
              style={{
                padding: "2px 6px",
                border: "1px solid white",
                marginRight: 2,
              }}
            >
              Orders
            </p>
            <p
              style={{
                padding: "2px 6px",
                backgroundColor: "white",
                border: "1px solid white",
                color: "#050d22",
              }}
            >
              Order history
            </p>
          </div>

          <table
            className="orderTable"
            style={{
              marginTop: 20,
              fontSize: 10,
              // display: "flex",
              // justifyContent: "space-between",
              // borderBottom: "1px solid white",
              // listStyle: "none",
            }}
          >
            <tbody>
              <tr>
                <td style={{ width: "7%" }}>
                  <FontAwesomeIcon icon={faCircle} size="xs" style={{ fontSize: 5, margin: 2 }} />
                  Date
                </td>
                <td style={{ width: "7%" }}>
                  <FontAwesomeIcon icon={faCircle} size="xs" style={{ fontSize: 5, margin: 2 }} />
                  Pair
                </td>
                <td style={{ width: "7%" }}>
                  <FontAwesomeIcon icon={faCircle} size="xs" style={{ fontSize: 5, margin: 2 }} />
                  Type
                </td>
                <td style={{ width: "10%" }}>
                  <FontAwesomeIcon icon={faCircle} size="xs" style={{ fontSize: 5, margin: 2 }} />
                  Price limit
                </td>
                <td style={{ width: "10%" }}>
                  <FontAwesomeIcon icon={faCircle} size="xs" style={{ fontSize: 5, margin: 2 }} />
                  Stop price
                </td>
                <td style={{ width: "10%" }}>
                  <FontAwesomeIcon icon={faCircle} size="xs" style={{ fontSize: 5, margin: 2 }} />
                  Filled volume
                </td>
                <td style={{ width: "10%" }}>
                  <FontAwesomeIcon icon={faCircle} size="xs" style={{ fontSize: 5, margin: 2 }} />
                  Filled perecent
                </td>
                <td style={{ width: "10%" }}>
                  <FontAwesomeIcon icon={faCircle} size="xs" style={{ fontSize: 5, margin: 2 }} />
                  Full volume
                </td>
                <td style={{ width: "7%" }}>
                  <FontAwesomeIcon icon={faCircle} size="xs" style={{ fontSize: 5, margin: 2 }} />
                  Total
                </td>
                <td style={{ width: "5%" }}>
                  <FontAwesomeIcon icon={faCircle} size="xs" style={{ fontSize: 5, margin: 2 }} />
                  Fee
                </td>
              </tr>
            </tbody>
          </table>

          <div>
            {data.map((item) => (
              <div
                style={{
                  backgroundColor: "#0c152d",
                  height: 20,
                  marginBottom: 20,
                }}
              />
            ))}
          </div>
        </div>
      </section>

      <AlphatradeRight />
    </div>
  );
};
export default Alphatrade;
